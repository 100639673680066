import React, {useCallback, useState} from "react";
import useQuery from "./useQuery";


export const useTabNavigator = () => {
    const [query, setQueries] = useQuery();
    const [activeTab, setActiveTab] = useState(query.get("tab") ?? "1");
    const [activeSubTab, setActiveSubTab] = useState(query.get("subTab") ?? "1");

    const onChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
        setActiveTab(newValue);
        query.delete("subTab");
        setQueries({
            tab: newValue
        });
    }, [query, setQueries]);

    const onChangeSubTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
        setActiveSubTab(newValue);
        query.set("subTab", newValue);
        setQueries(query);
    }, [query, setQueries]);

    return {
        activeTab,
        activeSubTab,
        onChangeTab,
        onChangeSubTab
    }
}
