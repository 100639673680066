import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {visuallyHidden} from "@mui/utils";
import React from "react";
import {IDataGridColumn, IGridHeaderProps} from "../props";


function GridHeader<T>({
                           columns,
                           onSortColumn,
                           sortDirection,
                           rowCount,
                           numSelected,
                           onSelectAll,
                           activeSortColumn,
                       }: IGridHeaderProps<T>) {
    const createSortHandler = (property: IDataGridColumn<T>) => (event: React.MouseEvent<unknown>) => {
        onSortColumn(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {undefined !== onSelectAll && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={undefined !== numSelected && numSelected > 0 && numSelected < rowCount}
                            checked={undefined !== rowCount && rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAll}
                            inputProps={{
                                'aria-label': 'Tout selectionner',
                            }}
                        />
                    </TableCell>
                )}
                {columns.map((headCell) => (
                    <TableCell
                        key={String(headCell.name)}
                        sortDirection={!headCell.disabledSort && activeSortColumn?.name === headCell.name ? sortDirection : false}
                    >
                        {
                            headCell.name === 'actions' ?
                                (
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 2,
                                    }}>
                                        {headCell.label}
                                    </Box>
                                ) :
                                (
                                    <TableSortLabel
                                        active={activeSortColumn?.name === headCell.name}
                                        direction={sortDirection}
                                        onClick={createSortHandler(headCell)}
                                    >
                                        {headCell.label}
                                        {(activeSortColumn && activeSortColumn?.name === headCell.name) && (
                                            <Box component="span" sx={visuallyHidden}>
                                                {sortDirection === 'desc' ? 'trié en ordre décroissant' : 'trié en ordre croissant'}
                                            </Box>
                                        )}
                                    </TableSortLabel>
                                )
                        }
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default GridHeader
