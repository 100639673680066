import {Close} from "@mui/icons-material";
import {Box, List, Paper, Typography} from "@mui/material";
import Popover from '@mui/material/Popover';
import * as React from "react";
import {useCallback} from "react";
import {NavLink} from "react-router-dom";
import {useAppDispatch} from "../../../../../hooks/useRedux";
import {clearNotifications, readNotification} from "../../../../../redux/notifications/notificationSlice";
import {StyledIconButton} from "../StyledIconButton";
import {INotificationListProps} from "./props";


export const NotificationList = ({anchorEl, onClose, open, title, notifications}: INotificationListProps) => {
    const dispatch = useAppDispatch()

    const markAsRead = useCallback((notification: typeof notifications[0]) => {
        dispatch(readNotification(notification))
    }, [dispatch])

    const clearAll = useCallback(() => {
        dispatch(clearNotifications())
    }, [dispatch])

    const renderNotificationItems = useCallback(() => {
        return notifications.map((notification, index) => (
            <NavLink
                key={index}
                to={notification.path}
                onClick={() => {
                    markAsRead(notification)
                    onClose()
                }}
                style={{
                    borderTopColor: '#E4E7EB',
                    borderTopStyle: 'solid',
                    borderTopWidth: '1px'
                }}
            >
                <Box
                    sx={{
                        fontSize: '.9rem',
                        py: 1,
                        px: 2

                    }}
                    dangerouslySetInnerHTML={{
                        __html: notification.content
                    }}
                />
            </NavLink>
        ))
    }, [markAsRead, notifications, onClose])

    const renderEmptyNotification = useCallback(() => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 2
                }}
            >
                <Typography variant="overline">
                    Aucune notification
                </Typography>
            </Box>
        )
    }, [])

    return (
        <>
            <Popover
                open={open}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                PaperProps={{
                    sx: {
                        width: '300px',
                        mt: 2.6,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        right: 1.6,
                        left: 'auto!important',
                        borderRadius: '0px 0px 20px 20px'
                    }
                }}
            >
                <Box
                    sx={{
                        py: 1,
                        px: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mt: 1,
                    }}
                >
                    <Typography variant="overline">
                        {title}
                    </Typography>
                    {notifications.length > 0 && (
                        <StyledIconButton
                            title="Tout marquer comme lu"
                            onClick={clearAll}
                        >
                            <Close/>
                        </StyledIconButton>
                    )}
                </Box>
                <Paper sx={{overflow: 'auto'}}>
                    <List sx={{
                        width: '100%',
                        '& a': {
                            display: 'block',
                            textDecoration: 'none',
                            color: 'text.primary',
                            '&:hover': {
                                background: '#F1F4F6',
                                cursor: 'pointer'
                            }
                        }
                    }}
                    >
                        {notifications.length > 0 ? renderNotificationItems() : renderEmptyNotification()}
                    </List>
                </Paper>
            </Popover>
        </>
    );
}
