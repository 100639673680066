import {FolderSharedOutlined} from "@mui/icons-material";
import {lazy} from "react";
import {IRouteType} from "../../../types";
import {CaseTrakingRoutesType} from "./types";


const CasesTrakingPage = lazy(() => import("../pages/case-tracking-page"));

export const caseRoutes: CaseTrakingRoutesType = {
    cases: {
        path: '/cases',
        component: <CasesTrakingPage/>,
        title: 'Mes dossiers',
        icon: <FolderSharedOutlined/>,
        permission: 'ROLE_REMOTE_CB',
    },
}


export const caseSidebarRoutes: Omit<IRouteType, 'component'>[] = Object.entries(caseRoutes)
    .map(([_, {component, ...rest}]) => (
        {
            ...rest,
        }
    ))
