import {zodResolver} from '@hookform/resolvers/zod';
import {TextField} from "@mui/material";
import {useCallback, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {ToastEnum} from "../../../constants";
import useError from "../../../hooks/useError";
import CrudForm from "../../../lib/ui/src/components/Form/CrudForm";
import {useToast} from "../../../hooks/useToast";
import {IResponse} from "../entities";

import {ResponseFormType, ResponseSchema} from '../schemas';
import {useCreateResponseMutation, useUpdateResponseMutation} from '../services/responseApi';


interface ResponseFormProps {
    open: boolean;
    onClose: () => void;
    response?: IResponse;
    question_id: number
}


const ResponseForm = ({open, onClose, response, question_id}: ResponseFormProps) => {
    const {register, handleSubmit, formState, reset, setValue} = useForm<ResponseFormType>({
        resolver: zodResolver(ResponseSchema)
    });
    const {errors} = formState;
    const {setErrors, getError, hasError} = useError(errors);
    const {showToast} = useToast()
    const [
        createResponse, {
            isLoading: isSubmitting,
            isError: hasCreationError,
            error: creationError,
            isSuccess: isCreatedWithSuccess
        }
    ] = useCreateResponseMutation()
    const [
        updateResponse, {
            isLoading: isEditSubmitting,
            isError: hasUpdatingError,
            error: updateError,
            isSuccess: isUpdatedWithSuccess
        }
    ] = useUpdateResponseMutation()

    useEffect(() => {
        if (response) {
            setValue('short_explaination', response.short_explaination)
        } else {
            reset()
        }
    }, [response, reset, setValue])

    useEffect(() => {
        setErrors(errors)
    }, [errors, setErrors])

    const resetForm = useCallback(() => {
        reset()
    }, [reset])

    useEffect(() => {
        if (isCreatedWithSuccess) {
            showToast('Response créée avec succès', ToastEnum.success)
            resetForm()
        }
        if (hasCreationError && creationError) {
            showToast('Erreur lors de la création de la response', ToastEnum.error)
            setErrors((
                creationError as any
            ).data.violations ?? {})
        }
        if (isUpdatedWithSuccess) {
            showToast('Notificiation modifiée avec succès', ToastEnum.success)
        }
        if (hasUpdatingError && updateError) {
            showToast('Erreur lors de la modification de la response', ToastEnum.error)
            setErrors((
                updateError as any
            ).data.violations ?? {})
        }
    }, [creationError, hasCreationError, hasUpdatingError, isCreatedWithSuccess, isUpdatedWithSuccess, resetForm, setErrors, showToast, updateError])


    const onFormSubmit = (data: ResponseFormType) => {
        if (response) {
            updateResponse({
                data: data as unknown as IResponse,
                id: response!.id as number,
                parentId: response.question_id as number
            })
        } else {
            createResponse({
                data: data as unknown as Partial<IResponse>,
                parentId: question_id
            })
        }
    }

    return (
        <CrudForm
            open={open}
            onClose={onClose}
            title={response ? `Modifier la response #${response.id}` : 'Créer une response'}
            isSubmitting={isSubmitting || isEditSubmitting}
            isEdition={!!response}
            onSubmit={handleSubmit(onFormSubmit)}
        >
            <TextField
                disabled={isSubmitting || isEditSubmitting}
                label="Réponse"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                margin="normal"
                inputProps={register('short_explaination')}
                error={hasError('short_explaination')}
                helperText={getError('short_explaination')}
            />
        </CrudForm>
    );
}

export default ResponseForm;
