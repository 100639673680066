import {useCallback, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/useRedux";
import {logout} from "../redux/features/authSlice";
import {authSelector} from "../redux/selectors/authSelector";


export const useAuth = () => {
    const dispatch = useAppDispatch();
    const {user} = useAppSelector(authSelector);
    const [isAuthenticated] = useState(!!user)
    // const [permissions] = useState(user?.role.permissions || [])

    // const canDo = useCallback((permission: string) => {
    //     if (
    //         (user?.role?.name === 'ROLE_UNITE' || user?.role?.name === 'ROLE_REGION') &&
    //         permission.includes('regions')) {
    //         return false;
    //     }
    //     if (
    //         user?.role?.name === 'ROLE_REGION' &&
    //         permission.includes('gestionnaires')) {
    //         return false;
    //     }
    //     if (user?.role?.name === 'ROLE_UNITE' &&
    //         permission.includes('unites')) {
    //         return false;
    //     }
    //     if (permission.endsWith(':list') && permissions.includes('resources.all:list')) {
    //         return true
    //     }
    //     return permissions.includes(permission)
    // }, [permissions, user])

    const isGranted = useCallback((role: string | string[]) => {
        if (typeof role === 'string') {
            if (['ROLE_AGENT', 'ROLE_REMOTE_CB'].includes(role) && user?.role === 'ROLE_ADMIN') {
                return true;
            }
            return user?.role === role;
        }
        return role.includes(user?.role || '')
    }, [user])

    const logoutHandler = () => {
        dispatch(logout())
    }

    return {
        user,
        isAuthenticated,
        // permissions,
        // canDo,
        isGranted,
        logout: logoutHandler
    }

}
