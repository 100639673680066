import React from "react";
import SidebarContent from "./SidebarContent/SidebarContent";
import Aside from "../Aside/Aside";
import {IAsideMenuProps} from "./props";
import {Logo} from "../Logo/Logo";


export const AsideMenu = ({onClose, isSidebarOpen}: IAsideMenuProps) => {

    return (
        <Aside
            logo={<Logo/>}
            onClose={onClose}
            open={isSidebarOpen}
        >
            <SidebarContent style={{
                marginTop: "1rem",
                paddingBottom: "36rem",
                gap: '.5rem',
                display: 'flex',
                flexFlow: 'column',
                '&::-webkit-scrollbar': {
                    width: '0.4rem',
                },
                '&::-webkit-scrollbar-track': {
                    background: '#f1f1f1',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#888',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: '#555',
                },
            }}/>
        </Aside>
    )

}

export default AsideMenu;
