import {
    CampaignOutlined,
    HeadsetMicOutlined,
    LayersOutlined,
    MapsHomeWorkOutlined,
    QueueOutlined,
    QuickreplyOutlined,
    SmsOutlined
} from "@mui/icons-material";
import {lazy} from "react";
import {IRouteType} from "../../../types";
import QuestionForm from "../components/QuestionForm";
import {GestionRoutesType} from "./types";


const NotificationsPage = lazy(() => import("../pages/notifications/notifications-page"));
const GuidesPage = lazy(() => import("../pages/guides/guides-page"));
const RubricsPage = lazy(() => import("../pages/guides/rubrics/rubrics-page"));
const BrigadesPage = lazy(() => import("../pages/brigades/brigades-page"));
const MessageTemplatesPage = lazy(() => import("../pages/message-templates/message-templates-page"));
const ConversationRequestsPage = lazy(() => import("../pages/conversation-requests/conversation-requests-page"));
const SmsPage = lazy(() => import("../pages/sms/sms-page"));

export const gestionRoutes: GestionRoutesType = {
    conversationRequests: {
        path: '/conversation-requests',
        component: <ConversationRequestsPage/>,
        title: 'Demandes conversations',
        icon: <QueueOutlined/>,
        permission: 'ROLE_AGENT'
    },
    messageTemplates: {
        path: '/message-templates',
        component: <MessageTemplatesPage/>,
        title: 'Modèles de messages',
        icon: <QuickreplyOutlined/>,
        permission: 'ROLE_AGENT'
    },
    sms: {
        path: '/sms',
        component: <SmsPage/>,
        title: 'Diffusion SMS',
        icon: <SmsOutlined/>,
        permission: 'ROLE_AGENT'
    },
    guides: {
        path: '/guides/questions',
        component: <GuidesPage/>,
        title: 'Reférentiel questions',
        icon: <HeadsetMicOutlined/>,
        permission: 'ROLE_AGENT',
        subRoutes: [
            {
                path: 'create',
                component: <QuestionForm/>,
                permission: 'ROLE_AGENT'
            },
            {
                path: ':id/edit',
                component: <QuestionForm/>,
                permission: 'ROLE_AGENT'
            }
        ]
    },
    rubrics: {
        path: '/rubrics',
        component: <RubricsPage/>,
        title: 'Rubriques',
        icon: <LayersOutlined/>,
        permission: 'ROLE_AGENT'
    },
    notifications: {
        path: '/notifications',
        component: <NotificationsPage/>,
        title: 'Notifications',
        icon: <CampaignOutlined/>,
        permission: 'ROLE_AGENT'
    },
    brigades: {
        path: '/brigades',
        component: <BrigadesPage/>,
        title: 'Brigades',
        icon: <MapsHomeWorkOutlined/>,
        permission: 'ROLE_AGENT'
    },
}


export const gestionSidebarRoutes: Omit<IRouteType, 'component'>[] = Object.entries(gestionRoutes)
    .map(([_, {component, ...rest}]) => (
        {
            ...rest,
        }
    ))
