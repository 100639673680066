import {CaseTrackCommentState} from "./types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICaseComment} from "../entities";

const initialChatState = {
    caseStep: null,
    caseTrak: null,
    comments: []
} as CaseTrackCommentState;

export const commentSlice = createSlice({
    name: 'comment',
    initialState: initialChatState,
    reducers: {
        setCaseTrak: (state, action: PayloadAction<CaseTrackCommentState['caseTrak']>) => {
            state.caseTrak = action.payload;
        },
        setCaseStep: (state, action: PayloadAction<CaseTrackCommentState['caseStep']>) => {
            state.caseStep = action.payload;
        },
        setComments: (state, action: PayloadAction<CaseTrackCommentState['comments']>) => {
            state.comments = action.payload;
        },
        addNewComment: (state, action: PayloadAction<ICaseComment>) => {
            state.comments = [action.payload, ...state.comments]
        }
    }
})

export const {addNewComment, setCaseTrak, setCaseStep, setComments} = commentSlice.actions;

export default commentSlice.reducer;
