import {Box, Stack, Typography} from "@mui/material";
import React from 'react';
import {IStatCardProps} from "./props";
import styled from "@emotion/styled";

const IconWrapper = styled(Box)(({theme}) => ({
    padding: 4,
    borderRadius: '50%',
}));

export const StatCard = ({icon, label, value, subLabel, sx, color = 'domain'}: IStatCardProps) => {
    return (
        <Box sx={{
            p: 2,
            border: 1,
            borderColor: 'divider',
            borderRadius: '20px!important',
            textAlign: 'center',
            background: 'rgb(248, 248, 251)',
            height: '200px',
            boxShadow: '0px 24px 48px -8px rgba(39, 76, 119, 0.16)',
            ...sx
        }}>
            <Stack justifyContent="center" alignItems="center" mb={2} spacing={1}>
                <IconWrapper
                    sx={{
                        '& svg': {
                            fontSize: 30,
                            color: `${color}.main`,
                            width: '50px',
                            height: '50px',
                        }
                    }}
                >
                    {icon}
                </IconWrapper>
            </Stack>
            <Typography mb={1} variant="h6" component="div">
                {label}
            </Typography>
            <Typography mb={1} variant="h4" component="div" fontWeight="bold">
                {value}
            </Typography>
            {subLabel && (
                <Typography variant="subtitle2" color="text.secondary">
                    {subLabel}
                </Typography>
            )}
        </Box>
    );
};
