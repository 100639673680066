import {HeadsetMicOutlined} from "@mui/icons-material";
import {lazy} from "react";
import {IRouteType} from "../../../types";
import {GestionRoutesType} from "./types";


const ChatPage = lazy(() => import("../pages/chat-page"));
const ConversationPage = lazy(() => import('../components/Conversation/Conversation'));
const EmptyConversationPage = lazy(() => import('../components/Conversation/EmptyConversation'));
export const chatRoutes: GestionRoutesType = {
    messenger: {
        path: '/messenger',
        component: <ChatPage/>,
        title: 'Messagerie',
        icon: <HeadsetMicOutlined/>,
        hasNestedRoutes: true,
        subRoutes: [
            {
                path: 'conversations/:id/threads/:threadId',
                component: <ConversationPage/>,
            },
            {
                path: '',
                component: <EmptyConversationPage/>,
                isIndex: true
            }
        ],
    },
}


export const chatSidebarRoutes: Omit<IRouteType, 'component'>[] = Object.entries(chatRoutes)
    .map(([_, {component, ...rest}]) => (
        {
            ...rest,
        }
    ))
