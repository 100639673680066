import React from 'react';
import {Box, Divider, Drawer, IconButton, Typography,} from '@mui/material';
import {Close as CloseIcon} from '@mui/icons-material';
import {ISideModalProps} from "./props";


export const SideModal = ({open, onClose, title, width, children, headerActions}: ISideModalProps) => {
    return (
        <Drawer
            sx={{
                '& .MuiDrawer-paper': {
                    width: width || 400,
                }
            }}
            anchor="right"
            open={open}
            variant={'temporary'}
            disableEscapeKeyDown={true}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                onClose();
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                >
                    <Typography variant="h6">{title}</Typography>
                </Box>
                <Box>
                    <IconButton onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                    {headerActions}
                </Box>
            </Box>
            <Divider/>
            {children}
        </Drawer>
    );
}
