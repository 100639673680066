import {TextField, TextFieldProps} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import React from "react";
import {IBrowserDatePickerProps} from "./props";


export const BrowserDatePicker = ({
                                      currentDate,
                                      minDate,
                                      maxDate,
                                      onSelectedDateChange,
                                      invalidMessage,
                                      isInvalid = false,
                                      isRequired = false,
                                      isDisabled = false,
                                      label,
                                      ...rest
                                  }: IBrowserDatePickerProps & TextFieldProps) => {

    return (
        <DatePicker
            value={currentDate}
            label={label}
            onChange={onSelectedDateChange}
            minDate={minDate}
            maxDate={maxDate}
            disabled={isDisabled}
            readOnly={isDisabled}
            inputFormat={'DD/MM/YYYY'}
            renderInput={(params: TextFieldProps) => (
                <TextField
                    {...params}
                    {...isInvalid && {error: true, helperText: invalidMessage ?? ''}}
                    {...isRequired && {required: true}}
                    {...isDisabled && {disabled: true}}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...rest}
                />
            )}
        />
    );
}

