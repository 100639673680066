import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {storage} from "../../utils";
import {INotification, INotificationsState} from "./types";


const persitedNotifications = storage.get<INotification[]>('notifications') || [];

const initialState: INotificationsState = {
    notifications: persitedNotifications,
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addNotification: (state, action: PayloadAction<INotification>) => {
            state.notifications.push(action.payload)
            storage.remember('notifications', state.notifications)
        },
        readNotification: (state, action: PayloadAction<INotification>) => {
            state.notifications = state.notifications.filter((notification) => notification.id !== action.payload.id)
            storage.remember('notifications', state.notifications)
        },
        clearNotifications: (state) => {
            state.notifications = []
            storage.remember('notifications', state.notifications)
        }
    },
})

export const {addNotification, readNotification, clearNotifications} = notificationSlice.actions;

export default notificationSlice.reducer;
