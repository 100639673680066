import {Box} from "@mui/material";
import {useGetRemoteCBNumericStatsQuery} from "../../stats/services/statsApi";
import LinearLoader from "../../../components/Loader/LinearLoader";
import {CaseTrackingStats} from "./CaseTrackingStats";

export const RemoteCbDashboard = () => {
    const {data, isLoading} = useGetRemoteCBNumericStatsQuery(undefined)

    if (isLoading) {
        return <LinearLoader/>
    }
    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        // large screens, 1 columns of 300px and 1fr
                        lg: '1fr',
                        // medium screens, 1 columns of 300px and 1fr
                        md: '1fr',
                        // small screens, 1 columns of 300px and 1fr
                        gap: '2rem',
                    }
                }}
            >
                {data && (
                    <>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: {
                                // large screens
                                lg: 'repeat(4, 1fr)',
                                // medium screens
                                md: 'repeat(3, 1fr)',
                                // small screens
                                xs: 'repeat(2, 1fr)',
                            },
                            gap: '1rem',
                            mb: '20px',
                            alignItems: 'flex-start',
                            alignContent: 'flex-start',
                        }}>
                            <CaseTrackingStats cases={data.item!.cases}/>
                        </Box>
                    </>
                )}
            </Box>
        </>
    )
}
