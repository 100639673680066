import AddIcon from "@mui/icons-material/Add";
import CachedIcon from '@mui/icons-material/Cached';
import DeleteIcon from "@mui/icons-material/Delete";
import {Box, Button, TextField, Toolbar, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {IGridToolBarProps} from "../props";


const GridToolBar = ({
                         onAdd,
                         onDelete,
                         onRefresh,
                         onSearch,
                         selectedItems,
                         title,
                         className,
                         sx,
                         onRefreshRender,
                         onAddRender,
                         onDeleteRender,
                         onSearchRender,
                         searchTimer,
                         children = null
                     }: IGridToolBarProps) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [debounceSearchValue, setDebounceSearchValue] = useState<string>(searchValue);

    useEffect(() => {
        const timer = setTimeout(() => setSearchValue(debounceSearchValue), searchTimer || 500);
        return () => clearTimeout(timer);
    }, [debounceSearchValue, searchTimer])

    useEffect(() => {
        if (onSearch) {
            onSearch(searchValue);
        }
    }, [onSearch, searchValue])

    const renderRefreshButton = () => {
        if (undefined !== onRefreshRender) {
            return onRefreshRender();
        }
        if (undefined !== onRefresh) {
            return (
                <Button
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: .5,
                        '& span': {
                            color: 'white'
                        }
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={onRefresh}
                >
                    <CachedIcon/>
                    <span>
                        Rafraîchir
                    </span>
                </Button>
            );
        }

        return null;
    }
    const renderAddButton = () => {
        if (undefined !== onAddRender) {
            return onAddRender();
        }
        if (undefined !== onAdd) {
            return (
                <Button
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: .5,
                        '& span': {
                            color: 'white'
                        }
                    }}
                    color="primary"
                    variant="contained"
                    onClick={onAdd}
                >
                    <AddIcon/>
                    <span>Ajouter</span>
                </Button>
            );
        }

        return null;
    }
    const renderDeleteButton = () => {
        if (undefined !== onDeleteRender) {
            return onDeleteRender(selectedItems);
        }
        if (undefined !== onDelete && undefined !== selectedItems && selectedItems.length > 0) {
            return (
                <Button
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: .5,
                        '& span': {
                            color: 'white'
                        }
                    }}
                    color="error"
                    variant="contained"
                    onClick={() => onDelete(selectedItems)}
                >
                    <DeleteIcon/>
                    <span>
                        Tous supprimer
                    </span>
                </Button>
            );
        }

        return null;
    }
    const renderSearchField = () => {
        if (undefined !== onSearchRender) {
            return onSearchRender(
                searchValue,
                setDebounceSearchValue
            )
        }

        if (undefined !== onSearch) {
            return (
                <TextField
                    onKeyUp={(e) => {
                        setDebounceSearchValue((
                            e.target as HTMLInputElement
                        ).value);
                    }}
                    label="Rechercher" variant="outlined"/>
            )
        }

        return null;
    }
    return (
        <Toolbar
            sx={{
                pl: {xs: 0, sm: 2},
                pr: {xs: 0, sm: 1},
                display: 'block',
                pt: {xs: 2, sm: 2},
                width: '100%',
                ...sx && sx,
            }}
            {...className && {className: className}}
        >
            <Box
                sx={{
                    display: 'flex',
                    flex: '1 1 100%',
                    justifyContent: {
                        sm: 'space-between',
                    },
                    width: '100%',
                    mb: 2,
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                    },
                    gap: {
                        xs: 3,
                    }
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <Typography
                        variant={'h6'}
                        sx={{
                            textTransform: 'lowercase',
                            '&::first-letter': {
                                textTransform: 'uppercase',
                            }
                        }}
                    >
                        {title}
                    </Typography>
                    {undefined !== selectedItems && selectedItems.length > 0 && (
                        <Typography
                            variant={'subtitle1'}
                            sx={{
                                textTransform: 'lowercase',
                                '&::first-letter': {
                                    textTransform: 'uppercase',
                                }
                            }}
                        >
                            {selectedItems.length} selected
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        mb: {
                            xs: 1,
                        },
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        maxWidth: 800,
                        width: '100%',
                    }}
                >
                    <>
                        {children}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                            }}
                        >
                            {renderSearchField()}
                            {renderAddButton()}
                            {renderDeleteButton()}
                            {renderRefreshButton()}
                        </Box>
                    </>
                </Box>
            </Box>
        </Toolbar>
    );
}

export default GridToolBar
