import {createApi} from "@reduxjs/toolkit/query/react";
import {AUTH_BASE_URL} from "../../../../config";
import axiosBaseQuery from "../../../../redux/rtk/axiosBaseQuery";
import {PostQueryArgs, PutQueryArgs} from "../../../../types";
import {toFormData} from "../../../../utils";
import {IUser} from "../../../user/entities";
import {IAvatar, IResetPassword} from "../../entities";
import {authenticate, logout} from "../features/authSlice";


export const profileApi = createApi({
    reducerPath: 'profileApi',
    baseQuery: axiosBaseQuery({baseUrl: AUTH_BASE_URL}),
    tagTypes: ['Profile'],
    endpoints: (builder) => ({
        getProfile: builder.query<IUser, null>({
            query: () => {
                return {
                    url: `/profile`,
                    method: 'GET',
                    credentials: 'include',
                }
            },
            transformResponse: (result: { item: IUser }) => result.item,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                const {data} = await queryFulfilled
                dispatch(
                    authenticate(data)
                )
            }
        }),
        updateProfile: builder.mutation<IUser, Omit<PutQueryArgs<IUser>, 'parentId' | 'id'>>({
            query({data}) {
                return {
                    url: `/profile`,
                    method: 'PUT',
                    credentials: 'include',
                    data: data,
                };
            },
            transformResponse: (result: { item: IUser }) => result.item,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                const {data} = await queryFulfilled
                dispatch(
                    authenticate(data)
                )
            }

        }),
        updatePassword: builder.mutation<string, Omit<PutQueryArgs<IResetPassword>, 'parentId' | 'id'>>({
            query({data}) {
                return {
                    url: `/password`,
                    method: 'PATCH',
                    credentials: 'include',
                    data: data,
                };
            },
            transformResponse: (result: { message: string }) => result.message,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                await queryFulfilled
                dispatch(logout())
            }
        }),
        updateAvatar: builder.mutation<IUser, Omit<PostQueryArgs<IAvatar>, 'parentId'>>({
            query({data}) {
                return {
                    url: `/profile/avatar`,
                    method: 'POST',
                    credentials: 'include',
                    data: toFormData(data),
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };
            },
            transformResponse: (result: { item: IUser }) => result.item,
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                const {data} = await queryFulfilled
                dispatch(
                    authenticate(data)
                )
            }
        }),
    }),
})

export const {
    useGetProfileQuery,
    useUpdateProfileMutation,
    useUpdatePasswordMutation,
    useUpdateAvatarMutation,
} = profileApi;
