import {Box, IconButton, Paper, Table, TableBody, TableContainer, TableRow, Tooltip, Typography} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import React, {ChangeEvent, useCallback, useState} from "react";
import {getShortComparator, stableSort} from "../../../../../utils";
import {readNestedObject} from "./helpers";
import GridHeader from "./GridHeader/GridHeader";
import {IDataGridAction, IDataGridColumn, IDataGridProps} from "./props";


function DataGrid<T>({
                         rows,
                         columns,
                         actions,
                         gridHeader,
                         gridFooter,
                         gridPanel,
                         onRowRender,
                         onCellRender,
                         onCellClick,
                         onSelectRow,
                         onSelectedRowsIds,
                         selectedRowsIds,
                         gridToolBar,
                         sx,
                     }: IDataGridProps<T>) {
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [activeSortColumn, setActiveSortColumn] = useState<IDataGridColumn<T> | null>(null);
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

    const isSelected = useCallback((id: number) => {
        if (undefined !== selectedRowsIds) {
            return selectedRowsIds.indexOf(id) !== -1
        }
        return false
    }, [selectedRowsIds]);

    const onSortColumn = (event: React.MouseEvent<unknown>, column: IDataGridColumn<T>) => {
        if (!column.disabledSort) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            setActiveSortColumn(column);
        }
    }

    const onCellClickHandler = (event: React.MouseEvent<unknown>, row: T, column: IDataGridColumn<T>) => {
        if (onCellClick) {
            onCellClick(row, column, event);
        }
    }

    const onSelectAllHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (undefined !== onSelectedRowsIds) {
            if (event.target.checked) {
                const newSelected = rows.map((row) => (
                    row as unknown as { id: number }
                ).id);
                onSelectedRowsIds(newSelected);
                setIsSelectAll(true);
                return;
            }
            onSelectedRowsIds([]);
            setIsSelectAll(false);
        }
    }, [rows, onSelectedRowsIds]);

    const onSelectRowHandler = useCallback((event: React.MouseEvent<unknown>, row: T) => {
        const {id} = row as unknown as { id: number };
        if (undefined !== onSelectRow) {
            onSelectRow(row);
        }
        if (undefined !== onSelectedRowsIds && undefined !== selectedRowsIds) {
            const selectedIndex = selectedRowsIds.indexOf(id);
            let newSelected: readonly number[] = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selectedRowsIds, id);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selectedRowsIds.slice(1));
            } else if (selectedIndex === selectedRowsIds.length - 1) {
                newSelected = newSelected.concat(selectedRowsIds.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selectedRowsIds.slice(0, selectedIndex),
                    selectedRowsIds.slice(selectedIndex + 1),
                );
            }

            onSelectedRowsIds(newSelected);
        }
    }, [onSelectRow, onSelectedRowsIds, selectedRowsIds]);

    const renderCell = (row: T, column: IDataGridColumn<T>) => {

        if (onCellRender) {
            return onCellRender(row, column);
        }

        if (column.render) {
            return column.render(row);
        }

        return readNestedObject(row, column.name as string)
    }

    const renderActions = (row: T) => {
        if (actions) {
            return (
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                }}
                >
                    {actions(row).map((action: IDataGridAction<T> | null | undefined, index) => {
                        if (!action) return null;

                        if (undefined !== action.render) {
                            return action.render({
                                row,
                                tooltip: action.tooltip,
                                icon: action.icon,
                                onClick: action.onClick,
                                label: action.label,
                                color: action.color,
                                disabled: action.disabled,
                            });
                        }
                        return (
                            <Tooltip
                                key={String((
                                    (
                                        row as unknown as any
                                    ).id
                                ) + index)}
                                title={action.label || false}>
                                <IconButton
                                    disabled={action.disabled}
                                    sx={{
                                        maxWidth: '30px',
                                        minWidth: '30px',
                                        height: '30px',
                                        p: 1.5
                                    }}
                                    onClick={() => action.onClick(row)}
                                    color={action.color}
                                    aria-label="edit" size="small">
                                    {action.icon}
                                </IconButton>
                            </Tooltip>
                        )
                    })}
                </Box>
            )
        }
        return null;
    }

    const renderRow = (row: T, isSelected: boolean) => {
        if (onRowRender) {
            return <TableRow
                hover
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                key={String((
                    row as unknown as any
                ).id)}
                selected={isSelected}
            >
                {onRowRender(row, isSelected)}
            </TableRow>
        }
        const labelId = `enhanced-table-checkbox-${(
            row as unknown as any
        ).id}`;
        const idCol = columns.find((column) => column.name === 'id') as IDataGridColumn<T>;

        return (
            <TableRow
                hover
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                key={String((
                    row as unknown as any
                ).id)}
                selected={isSelected}
            >
                {undefined !== onSelectedRowsIds && (
                    <TableCell
                        padding="checkbox">
                        <Checkbox
                            onClick={(event: React.MouseEvent<unknown>) => onSelectRowHandler(event, row)}
                            color="primary"
                            checked={isSelected}
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                        />
                    </TableCell>
                )}
                {idCol && (
                    <TableCell
                        onClick={event => onCellClickHandler(event, row, idCol)}
                        id={labelId}
                    >
                        {renderCell(row, idCol)}
                    </TableCell>
                )}
                {columns.map((column, index) => {
                    if (column.name === 'id' || column.name === 'actions') {
                        return null
                    }
                    return (
                        <TableCell
                            onClick={event => onCellClickHandler(event, row, column)}
                            key={String((
                                (
                                    row as unknown as any
                                ).id as number
                            ) + index + 1)}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex'
                                }}
                            >
                                <Typography
                                    color="textPrimary"
                                    variant="body1"
                                    component={'div'}
                                >
                                    {renderCell(row, column)}
                                </Typography>
                            </Box>
                        </TableCell>
                    )
                })}
                {!isSelected && (
                    <TableCell
                        key={String((
                            (
                                row as unknown as any
                            ).id as number
                        ) + columns.length + 1)}
                    >
                        {renderActions(row)}
                    </TableCell>
                )}
            </TableRow>
        )
    }

    const renderGridHeader = () => {
        return undefined !== gridHeader ? gridHeader({
                rows,
                columns,
                onSortColumn,
                sortDirection,
                activeSortColumn,
                rowCount: rows.length,
                numSelected: selectedRowsIds?.length || 0,
                onSelectAll: onSelectAllHandler,
            }
        ) : (
            <GridHeader
                columns={columns}
                onSortColumn={onSortColumn}
                sortDirection={sortDirection}
                rowCount={rows.length}
                activeSortColumn={activeSortColumn}
                numSelected={selectedRowsIds?.length || 0}
                onSelectAll={undefined !== onSelectedRowsIds ? onSelectAllHandler : undefined}
            />
        );
    }

    return (
        <>
            <Box sx={{
                width: '100%',
                ...sx && sx,
            }}>
                <Paper sx={{
                    width: '100%',
                    mb: 2,
                    p: 3,
                    backgroundColor: 'rgb(248, 248, 251)',
                    borderRadius: '20px'
                }}>
                    {undefined !== gridPanel && (
                        <>
                            {gridPanel({
                                columns,
                                rows,
                                selectedRowsIds,
                                selectedRows: isSelectAll ? rows : rows.filter((row) => selectedRowsIds?.includes((
                                    row as unknown as { id: number }
                                ).id)),
                            })}
                        </>
                    )}
                    {undefined !== gridToolBar && (
                        gridToolBar({
                            rows,
                            columns,
                            selectedRowsIds: selectedRowsIds,
                        })
                    )}
                    <TableContainer>
                        <Table
                            sx={{
                                width: '100%',
                            }}
                            aria-labelledby="tableTitle"
                        >
                            {renderGridHeader()}
                            <TableBody>
                                {stableSort((
                                    rows as any[]
                                ), getShortComparator(sortDirection, activeSortColumn?.name as any))
                                    .map((row) => {
                                        const isItemSelected = isSelected(row.id as number);
                                        return renderRow(row as unknown as T, isItemSelected);
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {undefined !== gridFooter && gridFooter}
                </Paper>
            </Box>
        </>
    )
}

export default DataGrid
