import {ArrowBack} from "@mui/icons-material";
import {Box, Breadcrumbs, Button, Container, Tooltip} from "@mui/material";
import {styled} from '@mui/material/styles'
import React, {Suspense, useState} from "react";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import AsideMenu from "../components/AsideMenu/AsideMenu";
import HeaderBar from "../components/HeaderBar/HeaderBar";
import SplashScreen from "../components/SplashScreen/SplashScreen";
import {ToastEnum} from "../constants";
import {useMenuBreakPoints} from "../hooks/useMenuBreakPoints";
import {useToast} from "../hooks/useToast";
import Toast from "../lib/ui/src/components/Toast/Toast";


import {IDashboardLayoutProps} from "./props";


export const DashboardLayout = ({children}: IDashboardLayoutProps) => {
    const {laptop: lgUp} = useMenuBreakPoints()
    const [isSidebarOpen, setSidebarOpen] = useState(lgUp)
    const navigate = useNavigate()
    const location = useLocation()
    const {id} = useParams()
    const {type, show, closeToast, message} = useToast()

    const toggleSidebar = () => setSidebarOpen(!isSidebarOpen)

    return (
        <>
            <DashboardLayoutRoot isSidebarOpen={isSidebarOpen}>
                <ChildrenLayoutRoot>
                    <ChildrenContainerRoot
                    >
                        <Suspense fallback={<SplashScreen/>}>
                            <>
                                <Box
                                    sx={{
                                        mt: '0'
                                    }}
                                >
                                    <Box sx={{
                                        display: 'flex',
                                        alignItems: {
                                            xs: 'flex-start',
                                            sm: 'center',
                                        },
                                        justifyContent: {
                                            xs: 'flex-start',
                                            sm: 'space-between'
                                        },
                                        mb: {
                                            xs: 3,
                                            sm: 3,
                                            md: 4,
                                            lg: 5,
                                        },
                                        mt: {
                                            xs: 6,
                                            sm: 3,
                                            md: 1,
                                            lg: 1,
                                        },
                                        flexDirection: {
                                            xs: 'column',
                                            sm: 'row',
                                        },
                                        gap: {
                                            xs: 1,
                                            sm: 2,
                                        }
                                    }}>
                                        <Box>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: {
                                                    xs: 'flex-start',
                                                    sm: 'center',
                                                },
                                                gap: 2,
                                                flexDirection: {
                                                    xs: 'column',
                                                    sm: 'row',
                                                }
                                            }}>
                                                {(
                                                    id || location.pathname.endsWith('/create')
                                                ) && (
                                                    <Tooltip title="Retour">
                                                        <Button
                                                            onClick={() => {
                                                                id ? navigate(`./../..`) : navigate(`./..`)
                                                            }}
                                                            color='primary'
                                                            variant='contained'>
                                                            <ArrowBack/>
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </Box>

                                        </Box>
                                        <Breadcrumbs aria-label="breadcrumb" sx={{
                                            '& *': {
                                                textDecoration: 'none',
                                            },
                                            '& a': {
                                                color: 'neutral.900',
                                                pl: .5,
                                                pr: .5,
                                                pt: .5,
                                                pb: .5,
                                                borderRadius: '5px',
                                                height: '24px',
                                                opacity: 1,
                                                '&:hover': {
                                                    opacity: .8,
                                                }
                                            },
                                        }}>
                                            <NavLink
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                to="/">
                                                Tableau de bord
                                            </NavLink>
                                        </Breadcrumbs>
                                    </Box>
                                    <>
                                        {children}
                                    </>

                                </Box>
                            </>
                        </Suspense>
                    </ChildrenContainerRoot>
                </ChildrenLayoutRoot>
            </DashboardLayoutRoot>
            <HeaderBar
                isSidebarOpen={isSidebarOpen}
                toggleSidebar={toggleSidebar}/>
            <AsideMenu
                isSidebarOpen={isSidebarOpen}
                onClose={() => {
                    setSidebarOpen(false)
                }}
            />
            {(
                type !== ToastEnum.none && show
            ) && (
                <Toast
                    positionX="right"
                    positionY="top"
                    timer={6000}
                    severity={type}
                    sx={{width: '100%'}}
                    open={show}
                    onClose={closeToast}
                >
                    {message}
                </Toast>
            )}
        </>
    )
}


const DashboardLayoutRoot = styled('div', {
    shouldForwardProp: (prop) => prop !== "isSidebarOpen",
})<{ isSidebarOpen: boolean }>(({theme, isSidebarOpen}) => (
    {
        display: 'flex',
        flex: '1 1 auto',
        maxWidth: '100%',
        paddingTop: 32,
        [theme.breakpoints.up('lg')]: {
            paddingLeft: isSidebarOpen ? 280 : '70px'
        }
    }
))

const ChildrenLayoutRoot = styled(Box)(({theme}) => (
    {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '100%',
        paddingTop: '16px',
        paddingBottom: '16px',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        }
    }
))

const ChildrenContainerRoot = styled(Container)(({theme}) => (
    {
        maxWidth: 100,
        marginTop: '2.8em',
        [theme.breakpoints.down('md')]: {
            px: '1rem',
            margin: 0
        },
        [theme.breakpoints.down('sm')]: {
            px: '1rem',
        }
    }
))
