import React from 'react';
import {ITabFormProps} from "./props";
import {Box, Divider} from "@mui/material";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CircularLoader from "../../../../../components/Loader/CircularLoader";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import SaveIcon from "@mui/icons-material/Save";


const TabForm = ({
                     children,
                     onSubmit,
                     isSubmitting,
                     onCancel,
                     isEdition,
                     formFooter,
                     isDisabled = false
                 }: ITabFormProps) => {
    return (
        <>
            <Box
            >
                <Box
                    component='form'
                    noValidate
                    id='form'
                    onSubmit={onSubmit}
                >
                    {children}
                </Box>
                <Divider/>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 2,
                    mt: 2
                }}>
                    <Button
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: .5
                        }}
                        disabled={isSubmitting}
                        autoFocus
                        onClick={onCancel}
                        variant='contained'
                        color='secondary'
                    >
                        <CloseIcon/>
                        <Typography>Quitter</Typography>
                    </Button>
                    <Button
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: .5
                        }}
                        disabled={isSubmitting || isDisabled}
                        form="form"
                        type="submit"
                        autoFocus
                        variant='contained'
                        color='primary'
                    >
                        {isSubmitting ?
                            <>
                                <CircularLoader/>
                                <Typography>En cours...</Typography>

                            </>

                            : <>
                                {isEdition ? <SaveAsIcon/> : <SaveIcon/>}
                                <Typography>{isEdition ? 'Enregistrer' : 'Créer'}</Typography>
                            </>
                        }
                    </Button>
                    {undefined !== formFooter && formFooter}
                </Box>
            </Box>
        </>
    )
}

export default TabForm;
