import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {lazy} from "react";
import {IRouteType} from "../../../types";
import {AuthRoutesType} from "./types";


const LoginPage = lazy(() => import("../../../modules/auth/pages/login-page"));
const LogoutPage = lazy(() => import("../../../modules/auth/pages/logout-page"));
const ProfilePage = lazy(() => import("../../../modules/auth/pages/profile-page"));

export const authRoutes: AuthRoutesType = {
    login: {
        path: '/login',
        component: <LoginPage/>,
        title: 'Connexion',
        icon: <LoginIcon/>
    },
    profile: {
        path: '/profile',
        component: <ProfilePage/>,
        title: 'Profile',
        icon: <AccountCircleIcon/>
    },
    logout: {
        path: '/logout',
        component: <LogoutPage/>,
        title: 'Déconnexion',
        icon: <ExitToAppIcon/>
    }
}


export const authSidebarRoutes: Omit<IRouteType, 'component'>[] = Object.entries(authRoutes)
    .map(([_, {component, ...rest}]) => ({
        ...rest,
    }))
