import {createApi} from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {IJsonResourceResponse} from "../../share/types";
import {IAgentStatsData, INumericStat, IRegisteredUserStat, IRemoteCBNumericStat, IStat} from "../entities";
import {toPythonDate} from "../../../utils";

const periods = (startDate?: string, endDate?: string) => {
    if (!startDate) {
        // start date equals to 30 days ago
        const startDateTmp = new Date()
        startDateTmp.setDate(startDateTmp.getDate() - 30)
        startDate = toPythonDate(startDateTmp)
    }
    if (!endDate) {
        // end date equals to today
        endDate = toPythonDate(new Date())
    }

    return {startDate, endDate}
}

export const statsApi = createApi({
    reducerPath: 'statsApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['NumericStats', 'ConversationRequestStats', 'ConversationStats', 'RegisteredUserStats', 'AgentsConversationsStats'],
    endpoints: (builder) => (
        {
            getNumericStats: builder.query<IJsonResourceResponse<INumericStat, 'single'>, undefined>({
                query: () => {
                    return {
                        url: `/stats/numerics`,
                        method: 'GET',
                        credentials: 'include',
                    }
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...Object.keys(result.item!).map((k) => (
                                {
                                    type: 'NumericStats' as const,
                                    k,
                                }
                            )),
                            {type: 'NumericStats', id: 'LIST'},
                        ]
                        : [{type: 'NumericStats', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<INumericStat, 'single'>) => result,
            }),
            getRegisteredUsersByPeriod: builder.query<IJsonResourceResponse<IRegisteredUserStat, 'single'>, {
                startDate?: string,
                endDate?: string
            }>({
                query: ({startDate, endDate}) => {

                    ({startDate, endDate} = periods(startDate, endDate))

                    return {
                        url: `/stats/users?start_date=${startDate}&end_date=${endDate}`,
                        method: 'GET',
                        credentials: 'include',
                    }
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...Object.keys(result.item!).map((k) => (
                                {
                                    type: 'RegisteredUserStats' as const,
                                    k,
                                }
                            )),
                            {type: 'RegisteredUserStats', id: 'LIST'},
                        ]
                        : [{type: 'RegisteredUserStats', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IRegisteredUserStat, 'single'>) => result,
            }),
            getAgentConversationsStatsByPeriod: builder.query<IJsonResourceResponse<IAgentStatsData, 'multiple'>, {
                startDate?: string,
                endDate?: string
            }>({
                query: ({startDate, endDate}) => {

                    ({startDate, endDate} = periods(startDate, endDate))

                    return {
                        url: `/stats/conversations?start_date=${startDate}&end_date=${endDate}`,
                        method: 'GET',
                        credentials: 'include',
                    }
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'AgentsConversationsStats' as const,
                                    id,
                                }
                            )),
                            {type: 'AgentsConversationsStats', id: 'LIST'},
                        ]
                        : [{type: 'AgentsConversationsStats', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IAgentStatsData, 'multiple'>) => results,
            }),
            getRemoteCBNumericStats: builder.query<IJsonResourceResponse<IRemoteCBNumericStat, 'single'>, undefined>({
                query: () => {
                    return {
                        url: `/stats/cbs/numerics`,
                        method: 'GET',
                        credentials: 'include',
                    }
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...Object.keys(result.item!).map((k) => (
                                {
                                    type: 'NumericStats' as const,
                                    k,
                                }
                            )),
                            {type: 'NumericStats', id: 'LIST'},
                        ]
                        : [{type: 'NumericStats', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<INumericStat, 'single'>) => result,
            }),
            getConversationRequestStatsByGenre: builder.query<IJsonResourceResponse<IStat, 'single'>, {
                startDate?: string,
                endDate?: string
            }>({
                query: ({startDate, endDate}) => {
                    ({startDate, endDate} = periods(startDate, endDate))
                    return {
                        url: `/stats/conversation-requests/genres?start_date=${startDate}&end_date=${endDate}`,
                        method: 'GET',
                        credentials: 'include',
                    }
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...Object.keys(result.item!).map((k) => (
                                {
                                    type: 'ConversationRequestStats' as const,
                                    k,
                                }
                            )),
                            {type: 'ConversationRequestStats', id: 'LIST'},
                        ]
                        : [{type: 'ConversationRequestStats', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IStat, 'single'>) => result,
            }),
            getConversationRequestStatsByRubric: builder.query<IJsonResourceResponse<IStat, 'single'>, {
                startDate?: string,
                endDate?: string
            }>({
                query: ({startDate, endDate}) => {
                    ({startDate, endDate} = periods(startDate, endDate))
                    return {
                        url: `/stats/conversation-requests/rubrics?start_date=${startDate}&end_date=${endDate}`,
                        method: 'GET',
                        credentials: 'include',
                    }
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...Object.keys(result.item!).map((k) => (
                                {
                                    type: 'ConversationRequestStats' as const,
                                    k,
                                }
                            )),
                            {type: 'ConversationRequestStats', id: 'LIST'},
                        ]
                        : [{type: 'ConversationRequestStats', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IStat, 'single'>) => result,
            }),
            getConversationStatsMessagesCountByRubric: builder.query<IJsonResourceResponse<IStat, 'single'>, {
                startDate?: string,
                endDate?: string
            }>({
                query: ({startDate, endDate}) => {
                    ({startDate, endDate} = periods(startDate, endDate))
                    return {
                        url: `/stats/conversations/rubrics/messages?start_date=${startDate}&end_date=${endDate}`,
                        method: 'GET',
                        credentials: 'include',
                    }
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...Object.keys(result.item!).map((k) => (
                                {
                                    type: 'ConversationRequestStats' as const,
                                    k,
                                }
                            )),
                            {type: 'ConversationRequestStats', id: 'LIST'},
                        ]
                        : [{type: 'ConversationRequestStats', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IStat, 'single'>) => result,
            }),
        }
    )
})

export const {
    useGetNumericStatsQuery,
    useGetRegisteredUsersByPeriodQuery,
    useGetAgentConversationsStatsByPeriodQuery,
    useGetRemoteCBNumericStatsQuery,
    useGetConversationRequestStatsByGenreQuery,
    useGetConversationRequestStatsByRubricQuery,
    useGetConversationStatsMessagesCountByRubricQuery,
} = statsApi;
