import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {
    DeleteItemArgs,
    GetItemQueryArgs,
    GetItemsQueryArgs,
    IJsonResourceResponse,
    PostItemArgs,
    PutItemArgs
} from "../../share/types";
import {IResponse} from "../entities";


export const responseApi = createApi({
    reducerPath: 'responseApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1/guides'}),
    tagTypes: ['Responses'],
    endpoints: (builder) => (
        {
            getQuestionResponses: builder.query<IJsonResourceResponse<IResponse, 'multiple'>, GetItemsQueryArgs>({
                    query: ({page, limit, searchTerm, parentId}) => {
                        const search = searchTerm ? `&q=${searchTerm}` : '';
                        const pageQuery = `&page=${page || 1}`;
                        const limitQuery = `&limit=${limit || 25}`;
                        return {
                            url: `/questions/${parentId}/responses?${pageQuery}${limitQuery}${search}`,
                            method: 'GET',
                            credentials: 'include',
                        };
                    },
                    providesTags: (result) =>
                        result
                            ? [
                                ...result.items!.map(({id}) => (
                                    {
                                        type: 'Responses' as const,
                                        id,
                                    }
                                )),
                                {type: 'Responses', id: 'LIST'},
                            ]
                            : [{type: 'Responses', id: 'LIST'}],
                    transformResponse: (results: IJsonResourceResponse<IResponse, 'multiple'>) => results,
                }
            ),

            getResponses: builder.query<IJsonResourceResponse<IResponse, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/responses?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Responses' as const,
                                    id,
                                }
                            )),
                            {type: 'Responses', id: 'LIST'},
                        ]
                        : [{type: 'Responses', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IResponse, 'multiple'>) => results,
            }),
            getResponse: builder.query<IResponse, GetItemQueryArgs>({
                query: ({id}) => {
                    return {
                        url: `/responses/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Responses', id}],
                transformResponse: (result: IJsonResourceResponse<IResponse, 'single'>) => result.item!,
            }),
            createResponse: builder.mutation<IResponse, PostItemArgs<IResponse>>({
                query: ({data, parentId}) => {
                    return {
                        url: `/questions/${parentId}/responses`,
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Responses', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IResponse, 'single'>) => result.item!,
            }),
            updateResponse: builder.mutation<IResponse, PutItemArgs<IResponse>>({
                query: ({data, id}) => {
                    return {
                        url: `/responses/${id}`,
                        method: 'PUT',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Responses', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IResponse, 'single'>) => result.item!,
            }),
            deleteResponse: builder.mutation<IResponse, DeleteItemArgs>({
                query: ({id}) => {
                    return {
                        url: `/responses/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Responses', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetQuestionResponsesQuery,
    useGetResponsesQuery,
    useGetResponseQuery,
    useCreateResponseMutation,
    useUpdateResponseMutation,
    useDeleteResponseMutation,
} = responseApi;
