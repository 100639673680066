import {useAuth} from "../../auth/hooks/useAuth";
import {RemoteCbDashboard} from "../components/RemoteCbDashboard";
import {AdminDashboard} from "../components/AdminDashboard";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";


export const DashboardPage = () => {

    const {user: auth} = useAuth()
    const navigateTo = useNavigate()

    useEffect(() => {
        if (!auth)
            navigateTo('/login')
    }, [auth, navigateTo])

    if (!auth) return null

    return auth!.role === 'ROLE_REMOTE_CB' ? <RemoteCbDashboard/> : <AdminDashboard/>
}
