import React, {useEffect} from "react";
import {HashRouter as Router, Route, Routes} from "react-router-dom";
import {PrivateRoute} from "./components/Route";
import PublicRoute from "./components/Route/PublicRoute";
import {authRoutes} from "./modules/auth/routes";
import {DashboardPage} from "./modules/dashboard/pages/dashboard-page";
import {routes} from "./routes";
import {useAuth} from "./modules/auth/hooks/useAuth";


const App = () => {
    const {user: auth} = useAuth()

    useEffect(() => {
        if (auth) {
            // window.io.emit('joined', {
            //     room_id: `support-${auth!.id}`
            // })
            // window.io.emit('joined', {
            //     room_id: `private-notifications`
            // })
        }

    }, [auth])

    return (
        <Router>
            <Routes>
                {Object.entries(authRoutes).map(([_, {path, permission, component}]) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            ['/login', 'logout'].includes(path) ? (
                                <PublicRoute>{component}</PublicRoute>
                            ) : (
                                <PrivateRoute permission={permission}>{component}</PrivateRoute>
                            )
                        }
                    />
                ))}
                <Route
                    key="index"
                    path='/'
                    element={<PrivateRoute><DashboardPage/></PrivateRoute>}
                />
                <Route
                    key="dashboard"
                    path='/dashboard'
                    element={<PrivateRoute><DashboardPage/></PrivateRoute>}
                />
                {Object.entries(routes).map(([_, route]) => {
                    const {hasNestedRoutes} = route
                    if (!hasNestedRoutes) {
                        return (
                            [
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={
                                        <PrivateRoute permission={route.permission}>
                                            {route.component}
                                        </PrivateRoute>
                                    }
                                />,
                                ...route.subRoutes?.map((subRoute) => {
                                    return (
                                        <Route
                                            key={`${route.path}${subRoute.path}`}
                                            path={`${route.path}/${subRoute.path}`}
                                            element={
                                                <PrivateRoute permission={route.permission}>
                                                    {subRoute.component}
                                                </PrivateRoute>
                                            }
                                        />
                                    )
                                }) || []
                            ]
                        )
                    }
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={
                                <PrivateRoute permission={route.permission}>
                                    {route.component}
                                </PrivateRoute>
                            }
                        >
                            {route.subRoutes?.map((subRoute) => {
                                return (
                                    <Route
                                        index={subRoute.isIndex}
                                        key={`${route.path}${subRoute.path}`}
                                        path={`${route.path}/${subRoute.path}`}
                                        element={
                                            <PrivateRoute wrap={false} permission={route.permission}>
                                                {subRoute.component}
                                            </PrivateRoute>
                                        }
                                    />
                                )
                            })}
                        </Route>
                    )
                })}
                <Route
                    key="not-found"
                    path='*'
                    element={(
                        <>
                            Page introuvable
                        </>
                    )}
                />
            </Routes>
        </Router>
    )
}

export default App;
