import {DATE_FIELDS, EXCLUDE_FIELDS_FROM_EDITION} from "../constants";
import {JSONStorage} from "../lib/packages/storage";
import {OrderType} from "../types";


export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export const getShortComparator = <Key extends keyof any>(
    order: OrderType,
    orderBy: Key
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const createFormData = (data: any) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
        }
    );
    return formData;
}

export const dateFormat = (date: Date | string, locale: 'fr-FR' | 'en-US' = 'fr-FR', withTime = true) => {
    // if date is a string
    if (typeof date === 'string') {
        date = new Date(date);
    }
    const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
        ...withTime && {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        } as const
    } as const;
    return date.toLocaleDateString(locale, options);
}

// french date format

export const toFrenchDate = (date: Date | string) => {
    // check if date is a string
    if (typeof date === 'string') {
        date = new Date(date);
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}


// english date format

export const toEnglishDate = (date: Date | string) => {
    if (null === date) {
        return '';
    }
    // check if date is a string
    if (typeof date === 'string') {
        date = new Date(date);
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // return date in this format: yyyy-mm-dd
    // return `${month > 9 ? month : '0' + month}/${day > 9 ? day : '0' + day}/${year}`;
    return `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day}`;
}

// format js Date to python datetime
export const toPythonDate = (date: Date | string) => {
    // if date is a string and it length is less than 8, it's invalid
    // then return 'invalid date'

    if (typeof date === 'string' && date.length < 10) {
        return 'invalid date';
    }

    // check if date is a string and convert it to a js Date
    if (typeof date === 'string') {
        date = new Date(date);
    }

    // check if date is a valid js Date
    if (isNaN(date.getTime())) {
        return 'invalid date';
    }

    // create python datetime object

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return `${year}-${month > 9 ? month : '0' + month}-${day > 9 ? day : '0' + day} ${hour > 9 ? hour : '0' + hour}:${minute > 9 ? minute : '0' + minute}:${second > 9 ? second : '0' + second}`;
}

// is python datetime valid ?
export const isPythonDateValid = (date: string) => {
    // date should be in this format: yyyy-mm-dd hh:mm:ss
    const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return regex.test(date);
}

// is date field
export const isDateField = (value: string) => {
    return DATE_FIELDS.includes(value);
}

// is exlude field
export const isExcludeField = (value: string) => {
    return EXCLUDE_FIELDS_FROM_EDITION.includes(value);
}


// convert object to form data
export const toFormData = (obj: any, form?: FormData, namespace?: string) => {
    // check if we have an initial form data else create a new one
    const formData = form || new FormData();
    let formKey: string;

    for (let property in obj) {
        if (obj.hasOwnProperty(property) && obj[property]) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }

            // if the property is an Array, we need to convert it to a string
            if (obj[property] instanceof Array) {
                // eslint-disable-next-line no-loop-func
                obj[property].forEach((element: any) => {
                    formData.append(String(formKey) + '[]', element);
                });
            }
                // if the property is an object, but not a File,
            // use recursivity.
            else if (typeof obj[property] === 'object' && !(
                obj[property] instanceof File
            )) {
                toFormData(obj[property], formData, property);
            } else {
                // if it's a string or a File object
                formData.append(formKey, obj[property]);
            }
        }
    }

    return formData;
}

// read nested object
export const readNestedObject = (obj: any, path: string) => {
    return path.split('.').reduce((o, i) => o[i], obj);
}

// check if array b is not included in array a
export const array_diff = (a: readonly number[], b: readonly number[]) => {
    return a.filter((value) => b.indexOf(value) === -1);
}

// intersection of two arrays
export const array_intersect = (a: readonly number[], b: readonly number[]) => {
    return a.filter((value) => b.indexOf(value) !== -1);
}

// merge two arrays
export const array_merge = (a: readonly number[], b: readonly number[]) => {
    return [...a, ...array_diff(b, a)];
}

export const frenchCurrencyFormat = (value: number) => parseFloat(value.toFixed(0)).toLocaleString('fr-FR');
// format number to n.x K, n.x M, n.x B,n.x T
export const formatNumber = (number: number) => {
    number = parseFloat(number.toFixed(0));
    if (number < 1000) {
        return number;
    }
    if (number < 1000000) {
        return (
            number / 1000
        ).toFixed(1) + ' K';
    }
    if (number < 1000000000) {
        return (
            number / 1000000
        ).toFixed(1) + ' M';
    }
    if (number < 1000000000000) {
        return (
            number / 1000000000
        ).toFixed(1) + ' B';
    }
    return (
        number / 1000000000000
    ).toFixed(1) + ' T';
}
export const isSubResource = (resource: string) => {
    return resource.includes('.');
}

// get sub resource
export const extractMainResource = (resource: string) => {
    return resource.split('.')[0];
}

export const formatRequestState = (state: 'PENDING' | 'APPROVED' | 'REJECTED') => {
    return {
        PENDING: 'EN ATTENTE' as const,
        APPROVED: 'APPROUVÉE' as const,
        REJECTED: 'REJETÉE' as const
    }[state]
}
export const getRequestStateColor = (state: 'PENDING' | 'APPROVED' | 'REJECTED') => {
    return {
        PENDING: 'warning' as const,
        APPROVED: 'success' as const,
        REJECTED: 'error' as const
    }[state]
}

export const formatDate = (date: Date) => {
    // dd/mm/yyyy hh:mm : (01/01/2020 12:00)
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    // prefix 0 if less than 10
    const dayStr = day < 10 ? `0${day}` : day;
    const monthStr = month < 10 ? `0${month}` : month;
    const hoursStr = hours < 10 ? `0${hours}` : hours;
    const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
    const secondsStr = seconds < 10 ? `0${seconds}` : seconds;
    return `${dayStr}/${monthStr}/${year} ${hoursStr}:${minutesStr}:${secondsStr}`;
}


export enum SizeSuffixes {
    bytes = 0,
    KB = 1,
    MB = 2,
    GB = 3,
}


export function formatFileSize(bytes: number, suffixIndex = SizeSuffixes.bytes): string {

    const suffixes = ['bytes', 'KB', 'MB', 'GB'];

    if (suffixIndex > suffixes.length - 1) {
        throw new Error(
            `You provided an out of range suffixIndex: ${suffixIndex}.
             The maximum value is ${suffixes.length - 1}.
             You can use the SizeSuffixes enum to get the correct value.
             `
        )
    }

    if (bytes < 1000) return +bytes.toFixed(2) + ' ' + suffixes[suffixIndex];

    return formatFileSize(bytes / 1024, suffixIndex + 1);
}

export const getFileMetadata = (file: File) => {
    const {name, size, type} = file;
    return {
        name,
        size,
        type,
        src: getMediaUrl(file),
    };
}

export const getFileMediaType = (file: File) => {
    const FILE_TYPES_MAP = {
        image: 'image',
        video: 'video',
        audio: 'audio',
        application: 'file',
    }
    const {type} = file;
    const [mediaType] = type.split('/');
    return FILE_TYPES_MAP[mediaType as keyof typeof FILE_TYPES_MAP] as "image" | "video" | "audio" | "file";
}

export const getMediaUrl = (file: File) => {
    return URL.createObjectURL(file);
}

export const convertUrlToFile = async (url: string, filename: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, {type: blob.type});
};

export const storage = new JSONStorage(
    // ttl for 1 day
    1000 * 60 * 60 * 24
)

export const generateSecureRoomId = (base: string, secret: string) => {

    const start = base.slice(4, 6);
    const end = base.slice(10, 12);

    const cleanedNumber = base.replace(/^\+226\d{4}/, '');

    const maskedNumber = start + '226' + cleanedNumber.replace(/\d/g, 'X');

    return 'user-' + maskedNumber + end + secret;

}

export const authenticateResource = (resource: string) => {
    return resource;
}

export const nl2br = (str: string) => {
    return (str + '').replace(/(\r\n|\n\r|\r|\n)/g, '<br/>');
}

export const humanRole = (role: 'ROLE_ADMIN' | 'ROLE_AGENT' | 'ROLE_REMOTE_CB' | 'ROLE_USER') => {
    return {
        ROLE_ADMIN: 'Administrateur',
        ROLE_AGENT: 'Agent',
        ROLE_REMOTE_CB: 'Agent CB',
        ROLE_USER: 'Utilisateur',
    }[role]
}

export const getCurrentPeriod = (period: 'current_week' | 'current_month' | 'current_year') => {
    const today = new Date();
    let startDate;

    if (period === 'current_week') {
        const day = today.getDay();
        const diff = today.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
        startDate = new Date(today.setDate(diff));
        startDate.setHours(0, 0, 0, 0); // set hours to the start of the day
    } else if (period === 'current_month') {
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
    } else {
        startDate = new Date(today.getFullYear(), 0, 1);
    }

    return {startDate: startDate, endDate: today};
}

