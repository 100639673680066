import {z} from "zod";


export const NotificationSchema = z.object({
    title: z.string().min(3, {
        message: 'Le titre doit contenir au moins 3 caractères',
    }).max(255, {
        message: 'Le titre doit contenir au maximum 255 caractères',
    }),
    type: z.string().refine((val: string) => ['danger', 'info', 'alert'].includes(val), {
        message: 'Le type doit être l\'un des suivants : "danger", "info" ou "alert"',
    }),
    short_description: z.string().optional().nullable(),
})

export type NotificationFormType = z.infer<typeof NotificationSchema>;

export const BrigadeSchema = z.object({
    name: z.string().min(3, {
        message: 'Le nom doit contenir au moins 3 caractères',
    }).max(50, {
        message: 'Le nom doit contenir au maximum 50 caractères',
    }),
    short_description: z.string().optional().nullable(),
    location: z.string().optional().nullable(),
    phone_number: z.string().optional().nullable(),
})

export type BrigadeFormType = z.infer<typeof BrigadeSchema>;

export const QuestionFormSchema = z.object({
    title: z.string().min(3, {
        message: 'Le titre doit contenir au moins 3 caractères',
    }).max(255, {
        message: 'Le titre doit contenir au maximum 255 caractères',
    }),
    // force the variants to have at least five sentences by splitting the string by \n and counting the number of sentences
    variants: z.string().refine((val: string) => val.split('\n').length >= 5, {
        message: 'La question doit avoir au moins 5 variantes',
    }),
    rubric_id: z.string({
            required_error: 'La rubrique est obligatoire',
        }
    ).regex(/^\d+$/, "La rubrique doit être un nombre"),
})

export type QuestionFormType = z.infer<typeof QuestionFormSchema>;

export const ResponseSchema = z.object({
    short_explaination: z.string().min(4, {
        message: 'La réponse doit contenir au moins 4 caractères',
    }),
    long_explanation: z.string().optional().nullable(),
})

export type ResponseFormType = z.infer<typeof ResponseSchema>;

export const RubricFormSchema = z.object({
    name: z.string().min(2, {
        message: 'Le nom doit contenir au moins 2 caractères',
    }).max(255, {
        message: 'Le nom doit contenir au maximum 255 caractères',
    }),
    description: z.string().optional().nullable(),
})

export type RubricFormType = z.infer<typeof RubricFormSchema>;

export const SMSSchema = z.object({
    title: z.string().min(3, {
        message: 'Le titre doit contenir au moins 3 caractères',
    }).max(255, {
        message: 'Le titre doit contenir au maximum 255 caractères',
    }),
    sms_type: z.string().refine((val: string) => ['danger', 'info', 'alert'].includes(val), {
        message: 'Le type doit être l\'un des suivants : "danger", "info" ou "alert"',
    }),
    content: z.string().min(
        3,
        'Le contenu doit contenir au moins 3 caractères',
    ).max(
        255,
        'Le contenu doit contenir au maximum 255 caractères',
    ),
})

export type SMSFormType = z.infer<typeof SMSSchema>;

export const MessageTemplateSchema = z.object({
    content: z.string({
        required_error: 'Le contenu est obligatoire',
    }).min(
        2,
        'Le contenu doit contenir au moins 2 caractères',
    ).max(
        255,
        'Le contenu doit contenir au maximum 255 caractères',
    ),
})

export type MessageTemplateFormType = z.infer<typeof MessageTemplateSchema>;
