import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {
    DeleteItemArgs,
    GetItemQueryArgs,
    GetItemsQueryArgs,
    IJsonResourceResponse,
    PostItemArgs,
    PutItemArgs
} from "../../share/types";
import {IBrigade} from "../entities";


export const brigadeApi = createApi({
    reducerPath: 'brigadeApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Brigades'],
    endpoints: (builder) => (
        {
            getBrigades: builder.query<IJsonResourceResponse<IBrigade, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/brigades?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Brigades' as const,
                                    id,
                                }
                            )),
                            {type: 'Brigades', id: 'LIST'},
                        ]
                        : [{type: 'Brigades', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IBrigade, 'multiple'>) => results,
            }),
            getBrigade: builder.query<IBrigade, GetItemQueryArgs>({
                query: (id) => {
                    return {
                        url: `/brigades/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Brigades', id}],
                transformResponse: (result: IJsonResourceResponse<IBrigade, 'single'>) => result.item!,
            }),
            createBrigade: builder.mutation<IBrigade, PostItemArgs<IBrigade>>({
                query: ({data}) => {
                    return {
                        url: '/brigades',
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Brigades', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IBrigade, 'single'>) => result.item!,
            }),
            updateBrigade: builder.mutation<IBrigade, PutItemArgs<IBrigade>>({
                query: ({data, id}) => {
                    return {
                        url: `/brigades/${id}`,
                        method: 'PUT',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Brigades', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IBrigade, 'single'>) => result.item!,
            }),
            deleteBrigade: builder.mutation<IBrigade, DeleteItemArgs>({
                query: ({id}) => {
                    return {
                        url: `/brigades/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Brigades', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetBrigadesQuery,
    useGetBrigadeQuery,
    useCreateBrigadeMutation,
    useUpdateBrigadeMutation,
    useDeleteBrigadeMutation,
} = brigadeApi;
