import styled from "@emotion/styled";
import {SwipeLeftAltOutlined, SwipeRightAltOutlined} from "@mui/icons-material";
import {AppBar, Box, IconButton, Toolbar, Typography} from "@mui/material";

import React, {useCallback} from "react";
import {useMenuBreakPoints} from "../../hooks/useMenuBreakPoints";
import {Logo} from "../Logo/Logo";
import {IHeaderProps} from "./props";


export const Header = ({children, toggleSidebar, open, ...props}: IHeaderProps) => {
    const {mobile} = useMenuBreakPoints();

    const renderMobileToggle = useCallback(() => {
        if (mobile) {
            return (
                <IconButton
                    sx={{
                        display: {
                            xs: 'inline-flex',
                        },
                        '& *': {
                            color: 'neutral.900',
                        },
                        background: 'transparent!important',
                        borderRadius: 'none!important',
                        '&::hover': {
                            backgroundColor: 'transparent!important'
                        }
                    }}
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleSidebar}
                >
                    {open ? <SwipeLeftAltOutlined/> : <SwipeRightAltOutlined/>}
                </IconButton>
            )
        } else {
            return (
                <IconButton
                    sx={{
                        display: {
                            xs: 'inline-flex',
                        },
                        '& *': {
                            color: 'neutral.900',
                        },
                        background: 'transparent!important',
                        borderRadius: 'none!important',
                        '&::hover': {
                            background: 'transparent!important'
                        },
                        '& svg': {
                            fontSize: '2.5rem'
                        }
                    }}
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={toggleSidebar}
                >
                    {open ? <SwipeLeftAltOutlined/> : <SwipeRightAltOutlined/>}
                </IconButton>
            )
        }
    }, [mobile, toggleSidebar, open]);

    return (
        <>
            <HeaderBarRoot
                sx={{
                    zIndex: 1000,
                    left: {
                        // lg: open ? 280 : 70
                        lg: 0
                    },
                    width: {
                        // lg: open ? 'calc(100% - 280px)' : 'calc(100% - 70px)'
                        lg: '100%'
                    }
                }}
                {...props}
            >
                <Toolbar
                    disableGutters
                    sx={{
                        minHeight: '55px!important',
                        left: 0,
                        px: 2
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '1rem',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            maxWidth: 280,
                            width: '100%'
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <Logo
                                sx={{
                                    mr: '.2rem',
                                    '& img': {
                                        objectFit: "contain",
                                        width: '100%'
                                    }
                                }}
                            />
                            <Typography
                                sx={{
                                    color: 'neutral.900',
                                    ml: '1rem',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '24px',
                                    fontSize: {
                                        // large screens
                                        lg: '1.2rem',
                                        // medium screens
                                        md: '1.2rem',
                                    }
                                }}
                                variant='h5'>
                                BNVAA
                            </Typography>
                        </Box>
                        {renderMobileToggle()}
                    </Box>
                    <Typography
                        sx={{
                            color: 'neutral.900',
                            ml: '1rem',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '24px',
                            fontSize: {
                                // large screens
                                lg: '1.2rem',
                                // medium screens
                                md: '1.2rem',
                            }
                        }}
                        variant='h5'>
                        Brigade Numérique de Veille, d'Alerte et d'Assistance
                    </Typography>
                    {children && children}
                </Toolbar>
            </HeaderBarRoot>
        </>
    )
}


const HeaderBarRoot = styled(AppBar)(({theme}: any) => ({
    backgroundColor: theme?.palette?.neutral['10'],
    boxShadow: theme?.overrideShadows['0'],
    borderRadius: '0px 0px 20px 20px'
}))

export default Header;
