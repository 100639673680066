import {SettingsOutlined} from "@mui/icons-material";
import {lazy} from "react";
import {IRouteType} from "../../../types";
import {SettingsRoutesType} from "./types";


const SettingsPage = lazy(() => import("../pages/settings-page"));

export const settingsRoutes: SettingsRoutesType = {
    settings: {
        path: '/settings',
        component: <SettingsPage/>,
        title: 'Paramètres',
        icon: <SettingsOutlined/>
    },
}


export const settingsSidebarRoutes: Omit<IRouteType, 'component'>[] = Object.entries(settingsRoutes)
    .map(([_, {component, ...rest}]) => (
        {
            ...rest,
        }
    ))
