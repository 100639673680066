import React from "react";

import {ISidebarProps} from "./props";
import {Box} from "@mui/material";


export const Sidebar = ({children, style, ...props}: ISidebarProps) => {
    return (
        <Box
            {...props}
            sx={{
                position: 'relative',
                ...style,
                flexGrow: 'unset',
            }}>
            {children}
        </Box>
    )
}
