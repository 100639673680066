import {ArchiveOutlined, HistoryOutlined, KeyOutlined} from "@mui/icons-material";
import {lazy} from "react";
import {IRouteType} from "../../../types";
import {AdminRoutesType} from "./types";
import {userRoutes} from "../../user/routes";

const ArchivesPage = lazy(() => import("../pages/archives-page"));
const ApiKeysPage = lazy(() => import("../pages/api-keys-page"));
const ConversationsTransferHistoryPage = lazy(() => import("../../gestion/pages/conversations-transfer-history/conversations-transfer-history-page"));
export const adminManagedRoutes: AdminRoutesType = {
    apiKeys: {
        path: '/api-keys',
        component: <ApiKeysPage/>,
        title: 'Clés API',
        icon: <KeyOutlined/>,
        permission: 'ROLE_ADMIN',
    },
    users: userRoutes['users'],
    agents: userRoutes['agents'],
    archives: {
        path: '/archives',
        component: <ArchivesPage/>,
        title: 'Archives conversations',
        icon: <ArchiveOutlined/>,
        permission: 'ROLE_ADMIN'
    },
    transferConversationHistory: {
        path: '/conversations/transfers/history',
        component: <ConversationsTransferHistoryPage/>,
        title: 'Historique transferts',
        icon: <HistoryOutlined/>,
        permission: 'ROLE_AGENT'
    }
}

export const adminRoutes = {
    ...adminManagedRoutes,
}


export const adminSidebarRoutes: Omit<IRouteType, 'component'>[] = [
    ...Object.entries(adminRoutes)
        .map(([_, {component, ...rest}]) => (
            {
                ...rest,
            }
        )),
]
