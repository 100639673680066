import {Box} from "@mui/material";
import React, {PropsWithChildren} from "react";


const AnonymousLayout = ({children}: PropsWithChildren<{}>) => {
    return (
        <>
            <Box sx={{
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: -1
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    width: '100%',
                    backgroundColor: 'neutral.100',
                }}>
                    {children}
                </Box>
            </Box>
        </>
    )
}

export default AnonymousLayout
