import {Box} from "@mui/material";
import {IGridPanelProps} from "../props";


function GridPanel({children, sx, className, ...props}: IGridPanelProps) {
    return (
        <Box
            sx={{
                width: '100%',
                ...sx && sx,
            }}
            {...className && {className: className}}
        >
            {children}
        </Box>
    );
}

export default GridPanel;
