import React from 'react';
import Box from '@mui/material/Box';
import styled from "@emotion/styled";
import {Avatar, Card, CardContent, Divider, Rating} from "@mui/material";
import Typography from "@mui/material/Typography";
import {OrdinalPosition} from "../../../lib/ui/src/components/OrdinalPosition";
import {AgentStat} from "../entities";

interface RateStatsProps {
    data: AgentStat[];
}

const StatCard = styled(Card)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 24px 48px -8px rgba(39, 76, 119, 0.16)',
    borderRadius: '20px!important',
    '&:last-child': {
        marginBottom: 0,
    },
    '& .MuiCardContent-root': {
        width: '100%',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        rowGap: '1rem',
    }
}));
export const RateStatsComponent: React.FC<RateStatsProps> = ({data}) => {
    return (
        <Box sx={{
            width: '100%',
            maxWidth: 280,
        }}>

            <StatCard>
                <CardContent>
                    <>
                        <Typography
                            color="textPrimary"
                            variant="caption"
                            sx={{
                                fontSize: '18px',
                                textTransform: 'none',
                                fontWeight: '600',
                                mb: '1rem',
                                '&::first-letter': {
                                    textTransform: 'uppercase'
                                }
                            }}
                        >
                            Top 5 des agents les mieux notés
                        </Typography>
                        {data.map((row: AgentStat, index) => (
                            <Box key={index}>
                                <Box
                                    sx={{
                                        pb: 5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '1rem',
                                            mb: '1rem',
                                        }}
                                    >
                                        <Avatar/>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                fontSize: '20px',
                                                textTransform: 'none',
                                                fontWeight: '600',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap',
                                                '&::first-letter': {
                                                    textTransform: 'uppercase',
                                                }
                                            }}
                                        >
                                            {row.username}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '1rem',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            variant="body1">
                                            <span
                                                style={{
                                                    marginRight: '.5rem',
                                                }}
                                            >
                                                Moyenne note :
                                            </span> {row.average_rating.toFixed(2)} / 5
                                        </Typography>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                            variant="body1">
                                            <span
                                                style={{
                                                    marginRight: '.5rem',
                                                }}
                                            >
                                                Tau de confiance :
                                            </span>
                                            {(row.average_rating / 5 * 100).toFixed(2)}%
                                        </Typography>
                                        <Rating precision={.1} name="read-only" value={row.average_rating} readOnly/>
                                        <Typography
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '.2rem',
                                            }}
                                            variant="body1">
                                            <span
                                                style={{
                                                    marginRight: '.5rem',
                                                }}
                                            >Classement : </span>
                                            <OrdinalPosition
                                                sx={{
                                                    fontSize: '1rem',
                                                    fontWeight: 'bold',
                                                    color: 'error.main',
                                                }}
                                                position={row.position}
                                            />
                                        </Typography>
                                    </Box>
                                </Box>
                                {index !== data.length - 1 && <Divider/>}
                            </Box>
                        ))}
                    </>
                </CardContent>
            </StatCard>
        </Box>
    );
};
