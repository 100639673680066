import {PieChartOutline} from "@mui/icons-material";
import {lazy} from "react";
import {IRouteType} from "../../../types";
import {StatsRoutesType} from "./types";


const StatsPage = lazy(() => import("../pages/stats-page"));

export const statsRoutes: StatsRoutesType = {
    stats: {
        path: '/stats',
        component: <StatsPage/>,
        title: 'Statistiques',
        icon: <PieChartOutline/>,
        permission: 'ROLE_AGENT'
    },
}


export const statsSidebarRoutes: Omit<IRouteType, 'component'>[] = Object.entries(statsRoutes)
    .map(([_, {component, ...rest}]) => (
        {
            ...rest,
        }
    ))
