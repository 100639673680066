import styled from "@emotion/styled";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ReactNode} from "react";
import theme from "../../../../../../theme";


export const HttpError = ({
                              message,
                              code,
                              onReset
                          }: { message: string, code: string | number, onReset: () => ReactNode }) => {
    return (
        <StyledHttpError>
            <Box sx={{
                '& .MuiTypography-h1': {
                    color: theme.palette.error.main,
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexFlow: 'column',
                height: '100%',
            }}>
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                    }}
                    variant={'h1'}>{code}</Typography>
                <Typography component={'p'}
                            sx={{
                                fontSize: 32,
                                fontWeight: 400,
                                color: theme.palette.text.secondary,
                                margin: 0,
                                textAlign: 'center',
                            }}>
                    {message}
                </Typography>
                {onReset()}
            </Box>
        </StyledHttpError>
    );
}

export const StyledHttpError = styled('div')({
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'hidden',
    '& .MuiBox-root .MuiTypography-h1': {
        fontSize: '34rem!important',
        fontWeight: 900,
        lineHeight: 1,
    },
});
