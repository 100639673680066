import {useCallback} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/useRedux";
import {IConversation} from "../../../../entities";
import {chatSelectors} from "../chatSelectors";
import {
    addConversation,
    setConversationsList,
    setCurrentConversation,
    setUserStatus,
    updateConversationList
} from "../chatSlice";
import {ChatState} from "../types";


export const useChatConversation = () => {

    const {conversation, notifications, userStatus, conversationsList} = useAppSelector(chatSelectors);

    const dispatch = useAppDispatch()

    const setConversation = useCallback((conversation: ChatState['conversation']) => {
        dispatch(setCurrentConversation(conversation))
    }, [dispatch])

    const setCurrentConversationUserStatus = useCallback((status: ChatState['userStatus']) => {
        dispatch(setUserStatus(status))
    }, [dispatch])

    const addConversationToList = useCallback((conversation: IConversation) => {
        dispatch(addConversation(conversation))
    }, [dispatch])

    const setCurrentUserConversationsList = useCallback((conversationsList: IConversation[]) => {
        dispatch(setConversationsList(conversationsList))
    }, [dispatch])

    const updateConversation = useCallback((conversationId: number, conversation: Partial<IConversation>) => {
        dispatch(updateConversationList({
            conversationId,
            conversation
        }))
    }, [dispatch])

    return {
        currentConversation: conversation,
        setCurrentConversation: setConversation,
        setCurrentConversationUserStatus,
        notifications,
        userStatus,
        conversationsList,
        addConversationToList,
        setCurrentUserConversationsList,
        updateConversation
    }
}
