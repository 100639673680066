import {useCallback} from "react";
import {ToastEnum} from "../constants";
import {toastSelector} from "../redux/toast/selectors";
import {hideToast, showErrorToast, showInfoToast, showSuccessToast, showWarningToast} from "../redux/toast/toastSlice";
import {useAppDispatch, useAppSelector} from "./useRedux";


export const useToast = () => {

    const {show, type, message} = useAppSelector(toastSelector);

    const dispatch = useAppDispatch()

    const showToast = useCallback((message: string, type = ToastEnum.success) => {
        switch (type) {
            case ToastEnum.success:
                dispatch(showSuccessToast({
                        message,
                    }
                ))
                break;
            case ToastEnum.error:
                dispatch(showErrorToast({
                    message,
                }))
                break;
            case ToastEnum.warning:
                dispatch(showWarningToast({
                    message,
                }))
                break;
            case ToastEnum.info:
                dispatch(showInfoToast({
                    message,
                }))
                break;
            default:
                dispatch(showSuccessToast({
                        message,
                    }
                ))
        }
    }, [dispatch])

    const closeToast = useCallback(() => {
        dispatch(hideToast())
    }, [dispatch])

    return {
        show,
        type,
        message,
        showToast,
        closeToast,
    }
}
