import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {
    DeleteItemArgs,
    GetItemQueryArgs,
    GetItemsQueryArgs,
    IJsonResourceResponse,
    PostItemArgs,
    PutItemArgs
} from "../../share/types";
import {INotification} from "../entities";


export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Notifications'],
    endpoints: (builder) => (
        {
            getNotifications: builder.query<IJsonResourceResponse<INotification, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/notifications?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Notifications' as const,
                                    id,
                                }
                            )),
                            {type: 'Notifications', id: 'LIST'},
                        ]
                        : [{type: 'Notifications', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<INotification, 'multiple'>) => results,
            }),

            getNotification: builder.query<INotification, GetItemQueryArgs>({
                query: ({id}) => {
                    return {
                        url: `/notifications/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Notifications', id}],
                transformResponse: (result: IJsonResourceResponse<INotification, 'single'>) => result.item!,
            }),

            createNotification: builder.mutation<INotification, PostItemArgs<INotification>>({
                query({data}) {
                    return {
                        url: `/notifications`,
                        method: 'POST',
                        credentials: 'include',
                        data: data,
                    };
                },
                invalidatesTags: [{type: 'Notifications', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<INotification, 'single'>) => result.item!,
            }),

            updateNotification: builder.mutation<INotification, PutItemArgs<INotification>>({
                query({data, id}) {
                    return {
                        url: `/notifications/${id}`,
                        method: 'PUT',
                        credentials: 'include',
                        data: data,
                    };
                },
                invalidatesTags: [{type: 'Notifications', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<INotification, 'single'>) => result.item!,
            }),

            deleteNotification: builder.mutation<INotification, DeleteItemArgs>({
                query({id}) {
                    return {
                        url: `/notifications/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Notifications', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetNotificationsQuery,
    useGetNotificationQuery,
    useCreateNotificationMutation,
    useUpdateNotificationMutation,
    useDeleteNotificationMutation,
} = notificationApi;
