import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {DeleteItemArgs, GetItemsQueryArgs, IJsonResourceResponse, PostItemArgs} from "../../share/types";
import {IApiKey} from "../entities";


export const apiKeyApi = createApi({
    reducerPath: 'apiKeyApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['ApiKeys'],
    endpoints: (builder) => (
        {
            getApiKeys: builder.query<IJsonResourceResponse<IApiKey, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/api-keys?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'ApiKeys' as const,
                                    id,
                                }
                            )),
                            {type: 'ApiKeys', id: 'LIST'},
                        ]
                        : [{type: 'ApiKeys', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IApiKey, 'multiple'>) => results,
            }),
            createApiKey: builder.mutation<IApiKey, PostItemArgs<IApiKey>>({
                query: ({data}) => {
                    return {
                        url: '/api-keys',
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'ApiKeys', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IApiKey, 'single'>) => result.item!,
            }),
            deleteApiKey: builder.mutation<IApiKey, DeleteItemArgs>({
                query: ({id}) => {
                    return {
                        url: `/api-keys/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'ApiKeys', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetApiKeysQuery,
    useCreateApiKeyMutation,
    useDeleteApiKeyMutation,
} = apiKeyApi;
