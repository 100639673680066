import {Box, Typography} from "@mui/material";
import {ReactNode} from "react";
import {StyledHttpError} from "./HttpError";
import theme from "../../../../../../theme";


export const NotAuthorize = ({message, onReset}: { message?: string, onReset: () => ReactNode }) => {
    return (
        <StyledHttpError>
            <Box sx={{
                '& .MuiTypography-h1': {
                    color: theme.palette.info.main,
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexFlow: 'column',
                height: '100%',
            }}>
                <Typography
                    sx={{
                        color: theme.palette.primary.main,
                    }}
                    variant={'h1'}>403</Typography>
                <Typography component={'p'}
                            sx={{
                                fontSize: 32,
                                fontWeight: 400,
                                color: theme.palette.text.secondary,
                                margin: 0,
                                textAlign: 'center',
                            }}>
                    {message || 'Vous n\'avez pas l\'autorisation d\'accéder à cette page.'}
                </Typography>
                {onReset()}
            </Box>
        </StyledHttpError>
    );
}
