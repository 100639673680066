import {Box, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {AccessTimeOutlined} from "@mui/icons-material";


const Clock = () => {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ml: 2,
                mr: 2,
                color: 'neutral.800',
                maxWidth: 120,
                minWidth: 120,
            }}
        >
            <AccessTimeOutlined
                color={'primary'}
                sx={{
                    fontSize: {
                        // large screens
                        lg: 32,
                        // medium screens
                        md: 32,
                        // small screens
                        xs: 18,
                    }
                }}
            />
            <Typography
                sx={{
                    fontSize: {
                        // large screens
                        lg: '1.3rem',
                        // medium screens
                        md: '1.1rem',
                        // small screens
                        xs: '1rem',
                    },
                    fontWeight: 400,
                    color: 'neutral.800',
                    fontFamily: 'Orbitron, sans-serif;'
                }}
                variant={'body2'}>
                {time.toLocaleTimeString(
                    'en-US',
                    {
                        hour12: false,
                    }
                )}
            </Typography>
        </Box>
    )
}

export default Clock;
