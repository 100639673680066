import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {
    DeleteItemArgs,
    GetItemQueryArgs,
    GetItemsQueryArgs,
    IJsonResourceResponse,
    PostItemArgs,
    PutItemArgs
} from "../../share/types";
import {IQuestion, IUnknownQuestion} from "../entities";


export const questionApi = createApi({
    reducerPath: 'questionApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1/guides'}),
    tagTypes: ['Questions', 'UnknownQuestions'],
    endpoints: (builder) => (
        {
            getQuestions: builder.query<IJsonResourceResponse<IQuestion, 'multiple'>, GetItemsQueryArgs & {
                filters?: string
            }>({
                query: ({page, limit, searchTerm, filters}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const filter = filters ? `&filters=${filters}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/questions?${pageQuery}${limitQuery}${search}${filter}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Questions' as const,
                                    id,
                                }
                            )),
                            {type: 'Questions', id: 'LIST'},
                        ]
                        : [{type: 'Questions', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IQuestion, 'multiple'>) => results,
            }),
            getUnknownQuestions: builder.query<IJsonResourceResponse<IUnknownQuestion, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/questions/unknowns?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'UnknownQuestions' as const,
                                    id,
                                }
                            )),
                            {type: 'UnknownQuestions', id: 'LIST'},
                        ]
                        : [{type: 'UnknownQuestions', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IUnknownQuestion, 'multiple'>) => results,
            }),
            getQuestion: builder.query<IQuestion, GetItemQueryArgs>({
                query: ({id}) => {
                    return {
                        url: `/questions/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Questions', id}],
                transformResponse: (result: IJsonResourceResponse<IQuestion, 'single'>) => result.item!,
            }),
            createQuestion: builder.mutation<IQuestion, PostItemArgs<IQuestion>>({
                query: ({data}) => {
                    return {
                        url: '/questions',
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Questions', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IQuestion, 'single'>) => result.item!,
            }),
            createRealQuestionFromUnknown: builder.mutation<IQuestion, { unknownQuestionId: number }>({
                query: ({unknownQuestionId}) => {
                    return {
                        url: `/questions/unknowns/${unknownQuestionId}`,
                        method: 'POST',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Questions', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IQuestion, 'single'>) => result.item!,
            }),
            updateQuestion: builder.mutation<IQuestion, PutItemArgs<IQuestion>>({
                query: ({data, id}) => {
                    return {
                        url: `/questions/${id}`,
                        method: 'PUT',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Questions', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IQuestion, 'single'>) => result.item!,
            }),
            deleteQuestion: builder.mutation<IQuestion, DeleteItemArgs>({
                query: ({id}) => {
                    return {
                        url: `/questions/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Questions', id: 'LIST'}],
            }),
            deleteUnknownQuestion: builder.mutation<IQuestion, { id: number }>({
                query: ({id}) => {
                    return {
                        url: `/questions/unknowns/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'UnknownQuestions', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetQuestionsQuery,
    useGetUnknownQuestionsQuery,
    useGetQuestionQuery,
    useCreateQuestionMutation,
    useCreateRealQuestionFromUnknownMutation,
    useUpdateQuestionMutation,
    useDeleteQuestionMutation,
    useDeleteUnknownQuestionMutation,
} = questionApi;
