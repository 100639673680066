import {RateStatsComponent} from "../components/RateStatsComponent";
import {AgentStat} from "../entities";

const AgentRatingStatsPage = ({data}: { data: AgentStat[] }) => {
    return (
        <RateStatsComponent data={data}/>
    );
}

export default AgentRatingStatsPage;
