import {configureStore} from "@reduxjs/toolkit";
import authReducer from "../../modules/auth/redux/features/authSlice";
import {authApi} from "../../modules/auth/redux/services/authApi";
import {profileApi} from "../../modules/auth/redux/services/profileApi"
import chatReducer from "../../modules/chat/components/redux/chat/chatSlice";
import {conversationApi} from "../../modules/chat/services/conversationApi";
import {messageApi} from "../../modules/chat/services/messageApi";
import {brigadeApi} from "../../modules/gestion/services/brigadeApi";
import {conversationRequestApi} from "../../modules/gestion/services/conversationRequestApi";
import {notificationApi} from "../../modules/gestion/services/notificationApi";
import {questionApi} from "../../modules/gestion/services/questionApi";
import {responseApi} from "../../modules/gestion/services/responseApi";
import {rubricApi} from "../../modules/gestion/services/rubricApi";
import {intentApi} from "../../modules/ia/services/intentApi";
import {statsApi} from "../../modules/stats/services/statsApi";
import {caseTrakingApi} from "../../modules/case-traking/services/caseTrakingApi";
import breadcrumbReducer from "../breadcrumb/breadcrumbSlice";
import toastReducer from "../toast/toastSlice";
import notificationReducer from "../notifications/notificationSlice";
import httpErrorReducer from "../features/http-errors/httpErrorSlice";
import {apiKeyApi} from "../../modules/admin/services/apiKeyApi";
import {ratingApi} from "../../modules/auth/redux/services/ratingApi";
import {smsApi} from "../../modules/gestion/services/smsApi";
import presenceStatusReducer from "../features/presence-status/presenceStatusSlice";
import {conversationTransferHistoryApi} from "../../modules/gestion/services/conversationTransferHistoryApi";
import {userApi} from "../../modules/user/services/userApi";
import commentReducer from "../../modules/case-traking/redux/commentSlice";
import {messageTemplateApi} from "../../modules/gestion/services/messageTemplateApi";


export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [notificationApi.reducerPath]: notificationApi.reducer,
        [brigadeApi.reducerPath]: brigadeApi.reducer,
        [conversationRequestApi.reducerPath]: conversationRequestApi.reducer,
        [questionApi.reducerPath]: questionApi.reducer,
        [responseApi.reducerPath]: responseApi.reducer,
        [caseTrakingApi.reducerPath]: caseTrakingApi.reducer,
        [conversationApi.reducerPath]: conversationApi.reducer,
        [messageApi.reducerPath]: messageApi.reducer,
        [rubricApi.reducerPath]: rubricApi.reducer,
        [statsApi.reducerPath]: statsApi.reducer,
        [intentApi.reducerPath]: intentApi.reducer,
        [apiKeyApi.reducerPath]: apiKeyApi.reducer,
        [ratingApi.reducerPath]: ratingApi.reducer,
        [smsApi.reducerPath]: smsApi.reducer,
        [messageTemplateApi.reducerPath]: messageTemplateApi.reducer,
        [conversationTransferHistoryApi.reducerPath]: conversationTransferHistoryApi.reducer,
        auth: authReducer,
        toast: toastReducer,
        chat: chatReducer,
        comment: commentReducer,
        breadcrumb: breadcrumbReducer,
        httpError: httpErrorReducer,
        notification: notificationReducer,
        presenceStatus: presenceStatusReducer,
    },
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({})
        .concat([
            authApi.middleware,
            userApi.middleware,
            profileApi.middleware,
            notificationApi.middleware,
            brigadeApi.middleware,
            conversationRequestApi.middleware,
            questionApi.middleware,
            responseApi.middleware,
            caseTrakingApi.middleware,
            conversationApi.middleware,
            messageApi.middleware,
            rubricApi.middleware,
            statsApi.middleware,
            intentApi.middleware,
            apiKeyApi.middleware,
            ratingApi.middleware,
            smsApi.middleware,
            conversationTransferHistoryApi.middleware,
            messageTemplateApi.middleware,
        ]),
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
