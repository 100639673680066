import {useMediaQuery} from "@mui/material";


export const useMenuBreakPoints = () => {
    const options = {
        defaultMatches: true,
        noSsr: false
    }
    const lgUp = useMediaQuery((theme: any) => theme?.breakpoints?.up('lg'), options)

    const mdUp = useMediaQuery((theme: any) => theme?.breakpoints?.down('md'), options)

    const smUp = useMediaQuery((theme: any) => theme?.breakpoints?.down('sm'), options)

    return {
        laptop: lgUp,
        tablet: mdUp,
        mobile: smUp
    }
}
