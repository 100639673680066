import {Avatar} from "@mui/material";
import React from "react";
import LogoImage from "../../assets/images/logo";
import {ILogoProps} from "./props";


export const Logo = ({sx}: ILogoProps) => {
    return (
        <>
            <Avatar
                sx={{
                    maxWidth: "100%",
                    ...sx && sx
                }}
                src={LogoImage}
                alt="Logo de la brigade numérique de la gendarmerie"/>
            {/*Loogo desactive*/}
        </>
    )
}
