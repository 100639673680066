import {Box, Drawer, useMediaQuery} from '@mui/material';
import React from 'react';
import {IAsideProps} from "./props";


export const Aside = ({children, logo, open, onClose, ...props}: IAsideProps) => {
    const lgUp = useMediaQuery((theme: any) => theme?.breakpoints?.up('lg'), {
        defaultMatches: true,
        noSsr: false
    })


    const content = (
        <>
            <Box
                {...props}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 'calc(100vh - 100px)'
                }}>
                {children}
            </Box>
        </>
    )

    if (lgUp) {
        return (
            <Drawer
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.1000',
                        border: "none",
                        color: 'neutral.900',
                        width: open ? 280 : 70,
                        top: '80px',
                        zIndex: 100,
                        pb: '35rem',
                        ...!open && {
                            '& li:last-child, .logout-button': {
                                width: '70px!important',
                            },
                            '& button': {
                                // minWidth: '100%',
                                paddingLeft: '13px',
                                '& > div': {
                                    display: 'none',
                                }
                            },
                            '& .collapse-list': {
                                pl: .5
                            },
                            '& .collapse-button ': {
                                marginRight: 'auto',
                                maxWidth: '70px',
                                paddingLeft: '8px',

                                '& .collapse-button-text': {
                                    display: 'none',
                                },
                                '& .collapse-button-icon': {
                                    px: 0,
                                    m: 0
                                }
                            }
                        }
                    },
                }}
                anchor="left"
                open={open}
                variant="permanent"
            >
                {content}
            </Drawer>
        )
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.1000',
                    color: 'neutral.900',
                    width: 280,
                },
            }}
            sx={{zIndex: (theme) => theme.zIndex.appBar}}
            variant="temporary">
            {content}
        </Drawer>
    )
}

export default Aside
