import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../../redux/rtk/axiosBaseQuery";
import {IRating} from "../../entities";
import {GetItemsQueryArgs, IJsonResourceResponse} from "../../../share/types";


export const ratingApi = createApi({
    reducerPath: 'ratingApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Ratings'],
    endpoints: (builder) => (
        {
            getAgentRatings: builder.query<IJsonResourceResponse<IRating, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/auth/ratings?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Ratings' as const,
                                    id,
                                }
                            )),
                            {type: 'Ratings', id: 'LIST'},
                        ]
                        : [{type: 'Ratings', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IRating, 'multiple'>) => results,
            }),
        }
    ),
});

export const {
    useGetAgentRatingsQuery,
} = ratingApi;
