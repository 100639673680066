import {AccountCircleOutlined, LogoutOutlined, NotificationsOutlined} from "@mui/icons-material";
import {Avatar, Badge, Box, Divider, MenuItem, Rating} from "@mui/material";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks/useRedux";
import AccountMenu from '../../lib/ui/src/components/Account/AccountMenu';
import Clock from '../../lib/ui/src/components/Clock/Clock';
import {NotificationList} from "../../lib/ui/src/components/NoticationList";
import {StyledIconButton} from "../../lib/ui/src/components/StyledIconButton";
import {useAuth} from "../../modules/auth/hooks/useAuth";
import {notificationSelector} from "../../redux/notifications/notificationSelectors";
import {addNotification} from "../../redux/notifications/notificationSlice";
import {Header} from "../Header/Header";
import {IHeaderBarProps} from "./props";
import notificationAudio from '../../assets/audio/notification.mp3'
import {authenticateResource} from "../../utils";
import {IConversation} from "../../modules/chat/entities";
import {useChatConversation} from "../../modules/chat/components/redux/chat/hooks";
import {ICaseComment} from "../../modules/case-traking/entities";
import {useCaseTrackComment} from "../../modules/case-traking/redux/hooks/useCaseTrackComment";


type ConversationRequestNotification = {
    conversation_reference: string
    conversation_request_id: number
    content: string
    created_at: string
    title: string
}

export const HeaderBar = ({isSidebarOpen, toggleSidebar}: IHeaderBarProps) => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const {addConversationToList} = useChatConversation()
    const {user} = useAuth();

    const navigate = useNavigate()

    const accountMenuRef = useRef<HTMLDivElement | null>(null);

    const notificationsMenuRef = useRef<HTMLDivElement | null>(null);

    const audioRef = useRef<HTMLAudioElement>(null);

    const dispach = useAppDispatch();

    const notifications = useAppSelector(notificationSelector)

    const {currentCaseStep, currentCaseTrak, addComment} = useCaseTrackComment()

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [navigate, user])


    useEffect(() => {
        window.io.on('conversation:request:new', (data: ConversationRequestNotification) => {
            dispach(addNotification({
                id: data.conversation_request_id,
                title: data.title,
                content: data.content,
                createdAt: data.created_at,
                path: '/conversation-requests'
            }))
            if (audioRef.current) {
                audioRef.current.muted = false;
                audioRef.current.play();
            }
        })

        window.io.on('conversation:transfered', ({conversation, message}: {
            conversation: IConversation,
            message: string
        }) => {
            dispach(addNotification({
                id: conversation.id,
                title: `Nouvelle conversation`,
                content: message,
                createdAt: new Date().toISOString(),
                path: `/messenger/conversations/${conversation.id}/threads/${conversation.conversation_reference}`
            }))

            addConversationToList(conversation)

            if (audioRef.current) {
                audioRef.current.muted = false;
                audioRef.current.play();
            }
        })

        window.io.on('comment:new', ({comment, title, message}: {
            comment: ICaseComment,
            title: string,
            message: string
        }) => {

            if ((currentCaseTrak && currentCaseTrak.id === comment.case_id) || (currentCaseStep && currentCaseStep.id === comment.step_id)) {
                addComment(comment)
            }

            if (!currentCaseTrak && !currentCaseStep) {
                dispach(addNotification({
                    id: String(comment.id) + new Date().toISOString(),
                    title: title,
                    content: message,
                    createdAt: new Date().toISOString(),
                    path: `/cases`
                }))
                if (audioRef.current) {
                    audioRef.current.muted = false;
                    audioRef.current.play();
                }
            }
        })

        return () => {
            window.io.off('conversation:request:new')
            window.io.off('conversation:transfered')
            window.io.off('comment:new')
        }
    }, [addComment, addConversationToList, currentCaseStep, currentCaseTrak, dispach])

    const handleMenuOpen = () => {
        setMenuOpen(true);
    }

    const handleMenuClose = () => {
        setMenuOpen(false);
    }

    const handleNotificationsOpen = useCallback(() => {
        setNotificationsOpen(true);
    }, []);

    const handleNotificationsClose = useCallback(() => {
        setNotificationsOpen(false);
    }, []);

    const handleLogout = () => {
        navigate('/logout');
    }

    if (!user) {
        return null;
    }

    return (
        <>
            <Header
                open={isSidebarOpen}
                toggleSidebar={toggleSidebar}
            >
                <Box sx={{flexGrow: 1}}/>
                <Clock/>
                <Divider
                    component={'div'}
                    orientation="vertical"
                    sx={{
                        border: 'solid 1px #D1D5DB',
                        width: '1px',
                        height: '55px',
                        ml: '1rem'
                    }}/>
                <StyledIconButton
                    onClick={handleNotificationsOpen}
                    sx={{
                        mr: '.5rem',
                        ml: '1.5rem',
                        color: 'neutral.900'
                    }}
                >
                    <Badge
                        ref={notificationsMenuRef}
                        badgeContent={notifications.length}
                        color="error"
                    >
                        <NotificationsOutlined/>
                    </Badge>
                </StyledIconButton>
                <Divider
                    component={'div'}
                    orientation="vertical"
                    sx={{
                        border: 'solid 1px #D1D5DB',
                        width: '1px',
                        height: '55px',
                        ml: '1rem'
                    }}/>
                <Avatar
                    color={'primary'}
                    sx={{
                        height: 40,
                        width: 40,
                        ml: 2,
                        '&:hover': {
                            cursor: 'pointer',
                        }
                    }}
                    src={user?.avatar ? authenticateResource(user.avatar) : undefined}
                    ref={accountMenuRef}
                    onClick={handleMenuOpen}
                >
                    <AccountCircleOutlined/>
                </Avatar>
                <Divider
                    component={'div'}
                    orientation="vertical"
                    sx={{
                        border: 'solid 1px #D1D5DB',
                        width: '1px',
                        height: '55px',
                        ml: '1.5rem'
                    }}/>
                <StyledIconButton
                    onClick={() => {
                        handleLogout()
                    }}
                    sx={{
                        mr: '.5rem',
                        ml: '1.5rem',
                        color: 'neutral.900'
                    }}
                >
                    <LogoutOutlined/>
                </StyledIconButton>
                <AccountMenu
                    anchorEl={accountMenuRef.current as HTMLElement}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    title={`Mon compte - ${user!.rate_position > 0 ? '#' + user!.rate_position : 'Non noté'}`}
                    fullname={(
                        <Box sx={{
                            display: 'flex',
                            alignContent: 'center',
                            flexFlow: 'column',
                            justifyContent: 'flex-start',
                            gap: '.5rem'
                        }}>
                            <span>{user!.username}</span>
                            <Rating
                                name="read-only"
                                value={parseFloat(user!.average_rating as unknown as string)}
                                readOnly
                            />
                        </Box>
                    )}
                >
                    <MenuItem
                        onClick={() => {
                            navigate('/profile')
                            handleMenuClose()
                        }}
                    >
                        Profile
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleLogout()
                        }}
                    >
                        Déconnexion
                    </MenuItem>
                </AccountMenu>
                <NotificationList
                    anchorEl={notificationsMenuRef.current as HTMLElement}
                    open={notificationsOpen}
                    onClose={handleNotificationsClose}
                    title={'Notifications'}
                    notifications={notifications}
                />
            </Header>
            <audio
                muted
                controls={false}
                ref={audioRef} style={{display: 'none'}}>
                <source src={notificationAudio} type="audio/mpeg"/>
            </audio>
        </>
    )
}

export default HeaderBar;
