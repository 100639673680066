import {ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from "react-redux";
import socketIO from "socket.io-client";
import App from './App';
import './assets/sass/app.scss';
import {StorageProvider} from "./lib/packages/storage";
import {store} from "./redux";
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import theme from "./theme";
import {SOCKET_IO_BASE_URL} from "./config";
import {storage} from "./utils";


declare global {
    interface Window {
        io: any
    }
}
window.io = socketIO(SOCKET_IO_BASE_URL, {
    transports: ['websocket'],
    query: {
        ...storage.exists('token') && {
            'token': storage.get('token')
        }
    }
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <StorageProvider>
                    <LocalizationProvider
                        dateFormats={{
                            month: 'MM',
                            year: 'YYYY',
                        }}
                        adapterLocale={'fr' as const}
                        dateAdapter={AdapterDayjs}
                    >
                        <App/>
                    </LocalizationProvider>
                </StorageProvider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
