import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {
    DeleteItemArgs,
    GetItemQueryArgs,
    GetItemsQueryArgs,
    IJsonResourceResponse,
    PostItemArgs,
    PutItemArgs
} from "../../share/types";
import {IRubric} from "../entities";


export const rubricApi = createApi({
    reducerPath: 'rubricApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Rubrics'],
    endpoints: (builder) => (
        {
            getRubrics: builder.query<IJsonResourceResponse<IRubric, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/rubrics?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Rubrics' as const,
                                    id,
                                }
                            )),
                            {type: 'Rubrics', id: 'LIST'},
                        ]
                        : [{type: 'Rubrics', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IRubric, 'multiple'>) => results,
            }),
            getRubric: builder.query<IRubric, GetItemQueryArgs>({
                query: (id) => {
                    return {
                        url: `/rubrics/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Rubrics', id}],
                transformResponse: (result: IJsonResourceResponse<IRubric, 'single'>) => result.item!,
            }),
            createRubric: builder.mutation<IRubric, PostItemArgs<IRubric>>({
                query: ({data}) => {
                    return {
                        url: '/rubrics',
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Rubrics', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IRubric, 'single'>) => result.item!,
            }),
            updateRubric: builder.mutation<IRubric, PutItemArgs<IRubric>>({
                query: ({data, id}) => {
                    return {
                        url: `/rubrics/${id}`,
                        method: 'PUT',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Rubrics', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IRubric, 'single'>) => result.item!,
            }),
            deleteRubric: builder.mutation<IRubric, DeleteItemArgs>({
                query: ({id}) => {
                    return {
                        url: `/rubrics/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Rubrics', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetRubricsQuery,
    useGetRubricQuery,
    useCreateRubricMutation,
    useUpdateRubricMutation,
    useDeleteRubricMutation,
} = rubricApi;
