import {StatCard} from "../../../lib/ui/src/components/StatCard";
import {formatNumber} from "../../../utils";
import {DoneAll, FolderSharedOutlined, PendingActionsOutlined, PendingOutlined} from "@mui/icons-material";
import {IRemoteCBNumericStat} from "../../stats/entities";

export const CaseTrackingStats = ({cases}: { cases: IRemoteCBNumericStat['cases'] }) => {
    return (
        <>
            <StatCard
                label={'Dossiers'}
                value={formatNumber(cases.total)}
                icon={<FolderSharedOutlined/>}
            />
            <StatCard
                label={'Dossiers en attente'}
                value={formatNumber(cases.pending)}
                icon={(<>
                    <FolderSharedOutlined/>
                    <PendingOutlined/>
                </>)}
            />
            <StatCard
                label={'Dossiers en cours'}
                value={formatNumber(cases.progress)}
                icon={(<>
                    <FolderSharedOutlined/>
                    <PendingActionsOutlined/>
                </>)}
            />
            <StatCard
                label={'Dossiers terminés'}
                value={formatNumber(cases.done)}
                icon={(<>
                    <FolderSharedOutlined/>
                    <DoneAll/>
                </>)}
            />
        </>
    )
}
