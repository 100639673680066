import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IConversation} from "../../../entities";
import {ChatState} from "./types";


const initialChatState = {
    conversation: null,
    notifications: 0,
    userStatus: 'Hors ligne',
    conversationsList: []
} as ChatState;

const chatSlice = createSlice({
    name: 'chat',
    initialState: initialChatState,
    reducers: {
        setCurrentConversation: (state, action: PayloadAction<ChatState['conversation']>) => {
            state.conversation = action.payload;
        },

        updateNotifications: (state, action: PayloadAction<ChatState['notifications']>) => {
            state.notifications = action.payload
        },

        clearCurrentConversation: (state) => {
            state.conversation = null;
        },

        setUserStatus: (state, action: PayloadAction<ChatState['userStatus']>) => {
            state.userStatus = action.payload
        },

        setConversationsList: (state, action: PayloadAction<ChatState['conversationsList']>) => {
            state.conversationsList = action.payload
        },

        updateConversationList: (state, action: PayloadAction<{
            conversationId: number,
            conversation: Partial<IConversation>
        }>) => {

            const {conversationId, conversation} = action.payload;

            const cIndex = state.conversationsList.findIndex(conversation => conversation.id === conversationId);

            if (-1 !== cIndex) {
                const c = state.conversationsList[cIndex]
                const oldUnread = undefined !== c.unread_count ? c.unread_count : 0;
                const newUnread = undefined !== conversation.unread_count ? conversation.unread_count + oldUnread : undefined;
                if (conversation.last_message) {
                    const conversationsList = state.conversationsList;
                    conversationsList.splice(cIndex, 1)
                    conversationsList.unshift({
                        ...c,
                        ...conversation,
                        unread_count: newUnread
                    })
                    state.conversationsList = conversationsList
                } else {
                    state.conversationsList[cIndex] = {
                        ...c,
                        ...conversation,
                        unread_count: newUnread
                    }
                }
            }
        },

        addConversation: (state, action: PayloadAction<IConversation>) => {
            state.conversationsList.unshift(action.payload)
        }
    }
})

export const {
    setCurrentConversation,
    updateNotifications,
    clearCurrentConversation,
    setUserStatus,
    updateConversationList,
    setConversationsList,
    addConversation
} = chatSlice.actions;

export default chatSlice.reducer
