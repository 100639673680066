import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {GetItemsQueryArgs, IJsonResourceResponse, PostItemArgs} from "../../share/types";
import {ISMS} from "../entities";


export const smsApi = createApi({
    reducerPath: 'smsApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['SMSs'],
    endpoints: (builder) => (
        {
            getSMSs: builder.query<IJsonResourceResponse<ISMS, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/sms?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'SMSs' as const,
                                    id,
                                }
                            )),
                            {type: 'SMSs', id: 'LIST'},
                        ]
                        : [{type: 'SMSs', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<ISMS, 'multiple'>) => results,
            }),
            createSMS: builder.mutation<ISMS, PostItemArgs<ISMS>>({
                query: ({data}) => {
                    return {
                        url: '/sms',
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'SMSs', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<ISMS, 'single'>) => result.item!,
            }),
        }
    ),
});

export const {
    useGetSMSsQuery,
    useCreateSMSMutation,
} = smsApi;
