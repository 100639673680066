import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IBreadcrumbState} from "../../types";

const initialState: IBreadcrumbState = {
    title: '',
} as IBreadcrumbState

const breadcrumbSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers: {
        updateBreadCrumbTitle: (state, action: PayloadAction<IBreadcrumbState>) => {
            state.title = action.payload.title;
        },
        clearBreadCrumbTitle: () => initialState,
    }
})

export const {updateBreadCrumbTitle, clearBreadCrumbTitle} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
