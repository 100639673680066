import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IToastState} from "../../types";
import {ToastEnum} from "../../constants";


const initialState: IToastState = {
    show: false,
    message: '',
    type: ToastEnum.success,
} as IToastState

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        showSuccessToast: (state, action: PayloadAction<Omit<Omit<IToastState, 'show'>, 'type'>>) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = ToastEnum.success;
        },
        showErrorToast: (state, action: PayloadAction<Omit<Omit<IToastState, 'show'>, 'type'>>) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = ToastEnum.error;
        },
        showWarningToast: (state, action: PayloadAction<Omit<Omit<IToastState, 'show'>, 'type'>>) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = ToastEnum.warning;
        },
        showInfoToast: (state, action: PayloadAction<Omit<Omit<IToastState, 'show'>, 'type'>>) => {
            state.show = true;
            state.message = action.payload.message;
            state.type = ToastEnum.info;
        },
        hideToast: (state) => {
            state.show = false;
            state.message = '';
            state.type = ToastEnum.none;
        }
    }
})

export const {showSuccessToast, showWarningToast, showInfoToast, showErrorToast, hideToast} = toastSlice.actions;

export default toastSlice.reducer;
