import React from 'react';
import {OrdinalPositionProps} from "./props";
import Typography from "@mui/material/Typography";

const getOrdinalText = (position: OrdinalPositionProps['position']) => {
    if (position === 1) {
        return 'Premier';
    }

    const suffix = position > 1 ? 'ième' : 'N/A';
    return `${position}${suffix}`;
};

export const OrdinalPosition = React.memo(({position, sx}: OrdinalPositionProps) => {
    return <Typography
        component={'span'}
        variant="h6"
        sx={{
            ...sx
        }}
    >
        {getOrdinalText(position)}
    </Typography>;
});
