import React, {PropsWithChildren, useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {DashboardLayout} from "../../layouts/DashboardLayout";
import {useAuth} from "../../modules/auth/hooks/useAuth";
import {StyledIconButton} from "../../lib/ui/src/components/StyledIconButton";
import {Dashboard} from "@mui/icons-material";
import theme from "../../theme";
import {NotAuthorize} from "../../lib/ui/src/components/Http/Errors/NotAuthorize";


export function PrivateRoute({children, permission, wrap = true}: IPrivateRouteProps) {
    const {isAuthenticated, user: auth, isGranted} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated || auth?.role === 'ROLE_USER') {
            navigate('/login')
        }
    }, [auth, isAuthenticated, navigate])


    if (undefined !== permission && !isGranted(permission)) {
        return <NotAuthorize onReset={() => (
            <StyledIconButton
                onClick={() => {
                    navigate('/')
                }}
                sx={{
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: theme.palette.primary.main,
                    backgroundColor: theme.palette.background.paper,
                    '&:hover': {
                        backgroundColor: theme.palette.background.paper,
                    },
                    gap: 2,
                    borderRadius: 4,
                }}>
                <>
                    <Dashboard/>
                    <span>
                        Retourner à la page d'accueil
                    </span>
                </>
            </StyledIconButton>
        )}/>
    }

    return (
        children ? (
            wrap ? <DashboardLayout>{children}</DashboardLayout> : <>{children}</>
        ) : <Outlet/>
    )
}


interface IPrivateRouteProps extends PropsWithChildren {
    permission?: string
    wrap?: boolean
}
