import {DashboardCustomizeOutlined, FolderOutlined, LogoutOutlined, SmartToyOutlined} from "@mui/icons-material";
import {Box} from "@mui/material";
import React, {useCallback} from "react";
import {CollapseSideBarItem, Sidebar, SidebarItem} from '../../../lib/ui/src/components/NavBar/Sidebar';
import {iaSidebarRoutes} from "../../../modules/ia/routes";
import {sidebarRoutes} from "../../../routes";
import {IRouteType} from "../../../types";
import {ISidebarContentProps} from "./props";
import {adminSidebarRoutes} from "../../../modules/admin/routes";
import {useAuth} from "../../../modules/auth/hooks/useAuth";
import {HorizontalSeparatorWithText} from "../../../lib/ui/src/components/HorizontalSeparatorWithText";
import {caseSidebarRoutes} from "../../../modules/case-traking/routes";


export const SidebarContent = ({...props}: ISidebarContentProps) => {
    const {isGranted} = useAuth()

    const renderSidebarItem = useCallback((route: Omit<IRouteType, 'component'>) => {
        return (
            <SidebarItem
                key={route.path}
                path={route.path}
                title={route.title}
                icon={route.icon}
                navLinkClass={''}
            />
        )
    }, [])

    const filterRoutes = useCallback((routes: Omit<IRouteType, 'component'>[]) => {
        return routes
    }, [])

    const renderSidebarItems = useCallback((routes: Omit<IRouteType, 'component'>[]) => {
        return filterRoutes(routes).map(route => renderSidebarItem(route))
    }, [filterRoutes, renderSidebarItem])

    return (
        <Sidebar
            {...props}>
            <SidebarItem
                path='/'
                icon={<DashboardCustomizeOutlined/>}
                title={'Tableau de bord'}
                key={'dashboard'}
            />
            {isGranted(['ROLE_ADMIN', 'ROLE_REMOTE_CB']) && (
                <>
                    <HorizontalSeparatorWithText
                        text={'Suivi dossier'}
                        icon={<FolderOutlined/>}
                    />
                    {renderSidebarItems(caseSidebarRoutes)}
                </>
            )}
            {isGranted('ROLE_ADMIN') && (
                <>
                    <HorizontalSeparatorWithText
                        text={'Administration'}
                    />
                    {renderSidebarItems(adminSidebarRoutes)}
                </>
            )}
            {isGranted(['ROLE_AGENT', 'ROLE_ADMIN']) && (
                <>
                    <HorizontalSeparatorWithText
                        text={'Gestion'}
                    />
                    <CollapseSideBarItem
                        title={'Chat Bot'}
                        icon={<SmartToyOutlined/>}
                        openIcon={<SmartToyOutlined/>}
                        key={'chat-bot'}
                        open={false}
                    >
                        {renderSidebarItems(iaSidebarRoutes)}
                    </CollapseSideBarItem>
                    {renderSidebarItems(sidebarRoutes)}
                </>
            )}
            <Box
                className={'logout-button'}
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    zIndex: 1000,
                    background: '#E7ECEF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '280px',
                }}
            >
                <SidebarItem
                    path='/logout'
                    icon={<LogoutOutlined/>}
                    title={'Déconnexion'}
                    key={'logout'}
                    navLinkClass={''}
                    sx={{
                        background: '#E7ECEF',
                        width: '240px',
                    }}
                />
            </Box>
        </Sidebar>
    )

}

export default SidebarContent;
