import {BaseQueryFn,} from '@reduxjs/toolkit/query';
import {Mutex} from 'async-mutex';
import type {AxiosError, AxiosRequestConfig} from 'axios'
import axios from 'axios'
import {API_BASE_URL} from "../../config";
import {logout} from "../../modules/auth/redux/features/authSlice";
import {storage} from "../../utils";
import {clearHttpError, updateHttpError} from '../features/http-errors/httpErrorSlice'
// Create a new mutex
const mutex = new Mutex();

// create secureStorage

const axiosBaseQuery =
    (
        {baseUrl}: { baseUrl?: string } = {baseUrl: ``}
    ): BaseQueryFn<{
        url: string
        method: AxiosRequestConfig['method']
        data?: AxiosRequestConfig['data']
        params?: AxiosRequestConfig['params'],
        headers?: AxiosRequestConfig['headers']
    },
        unknown,
        unknown> =>
        async ({url, method, data, params, headers}, api) => {
            // wait until the mutex is available without locking it
            await mutex.waitForUnlock();
            // get the token from the storage
            let token = storage.get<string | null>('token')
            // watch for changes in the token
            storage.watch<string | null>('token', (data) => {
                token = data
            })

            try {
                const res = (
                    await axios({
                        url: baseUrl + url,
                        method,
                        data,
                        params,
                        headers: {
                            "Access-Control-Allow-Credentials": true,
                            "Access-Control-Allow-Origin": '*',
                            "Content-Type": "application/json",
                            ...(
                                token ? {Authorization: `Bearer ${token}`} : {}
                            ),
                            ...(
                                headers || {}
                            )
                        }
                    })
                ).data
                // clear the previous http error
                api.dispatch(clearHttpError())
                return {data: res}
            } catch (axiosError) {
                let err = axiosError as AxiosError<{
                    message: string,
                    code: number | string,
                }, any>


                // if the error is a 401, logout the user
                if (err.response?.status === 401) {
                    api.dispatch(logout())
                } else if (err.response?.status === 403 || (
                    undefined !== err.response && err.response?.status > 422
                )) {
                    api.dispatch(updateHttpError({
                        code: err.response?.data?.code,
                        message: err.response?.data?.message,
                        status: err.response?.status
                    }))
                } else if (err.response?.status === 422) {
                    api.dispatch(clearHttpError())
                } else if (err.response?.status === 404) {
                    api.dispatch(updateHttpError({
                        code: err.response?.data?.code,
                        message: err.response?.data?.message,
                        status: err.response?.status
                    }))
                }

                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }

export default axiosBaseQuery;
