import {useAppDispatch, useAppSelector} from "../../../../hooks/useRedux";
import {commentSelector} from "../commentSelector";
import {useCallback} from "react";
import {addNewComment, setCaseStep, setCaseTrak, setComments} from "../commentSlice";
import {ICase, ICaseComment, ICaseStep} from "../../entities";

export const useCaseTrackComment = () => {

    const dispatch = useAppDispatch()

    const {caseStep, caseTrak, comments} = useAppSelector(commentSelector)

    const setCurrentCaseTrak = useCallback((caseTrak: ICase) => {
        dispatch(setCaseTrak(caseTrak))
    }, [dispatch])

    const setCurrentCaseStep = useCallback((caseStep: ICaseStep) => {
        dispatch(setCaseStep(caseStep))
    }, [dispatch])

    const setCommentsList = useCallback((newComments: ICaseComment[]) => {
        dispatch(setComments(newComments))
    }, [dispatch])

    const addComment = useCallback((comment: ICaseComment) => {
        dispatch(addNewComment(comment))
    }, [dispatch])


    return {
        currentCaseStep: caseStep,
        currentCaseTrak: caseTrak,
        comments,
        setCommentsList,
        addComment,
        setCurrentCaseStep,
        setCurrentCaseTrak
    }
}
