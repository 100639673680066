import React, {PropsWithChildren} from "react";


import {Outlet} from "react-router-dom";
import AnonymousLayout from "../../layouts/AnonymousLayout";


type IPublicRouteProps = PropsWithChildren<{}>;


const PublicRoute = ({children}: IPublicRouteProps) => {
    return children ? <AnonymousLayout>{children}</AnonymousLayout> : <Outlet/>
}

export default PublicRoute
