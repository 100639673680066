import {chatRoutes, chatSidebarRoutes} from "../modules/chat/routes";
import {gestionRoutes, gestionSidebarRoutes} from "../modules/gestion/routes";
import {iaRoutes} from "../modules/ia/routes";
import {settingsRoutes, settingsSidebarRoutes} from "../modules/settings/routes";
import {adminRoutes} from "../modules/admin/routes";
import {statsRoutes, statsSidebarRoutes} from "../modules/stats/routes";
import {caseRoutes} from "../modules/case-traking/routes";
import {userRoutes} from "../modules/user/routes";


export const routes = {
    ...iaRoutes,
    ...gestionRoutes,
    ...statsRoutes,
    ...userRoutes,
    ...chatRoutes,
    ...adminRoutes,
    ...settingsRoutes,
    ...caseRoutes,
}

export const sidebarRoutes = [
    ...chatSidebarRoutes,
    ...gestionSidebarRoutes,
    ...statsSidebarRoutes,
    ...settingsSidebarRoutes,
]
