import {IStaticData} from "../types";


export enum ToastEnum {
    success = 'success',
    error = 'error',
    info = 'info',
    warning = 'warning',
    none = 'none'
}


// genre list
export const GENRE_LIST: IStaticData[] = [
    {
        id: 1,
        libelle: 'Masculin',
        value: 'MASCULIN'
    },
    {
        id: 2,
        libelle: 'Feminin',
        value: 'FEMININ'
    },
]

// fields to exclude from the edit form
export const EXCLUDE_FIELDS_FROM_EDITION = [
    'id',
    'date_creation',
    'date_modification',
    'agent_id',
    'photo'
];

// date fields list

export const DATE_FIELDS = [
    'date_naissance',
    'date_debut',
    'date_deces',
    'date_fin',
    'date_fin_effective',
    'date',
    'date_creation',
    'date_modification',
    'date_union',
    'date_debut_repos',
    'date_fin_repos',
    'date_integration',
]

// last date of the current year
export const LAST_DATE_OF_CURRENT_YEAR = new Date(new Date().getFullYear(), 11, 31);

export const CURRENT_DATE = new Date()

// first date of the current year
// updated: now it's the 3 previous month from the current month
export const PREVIOUS_MONTH_DATE = new Date(CURRENT_DATE.setMonth(CURRENT_DATE.getMonth() - 1));
