import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {
    DeleteItemArgs,
    GetItemQueryArgs,
    GetItemsQueryArgs,
    IJsonResourceResponse,
    PostItemArgs,
    PutItemArgs
} from "../../share/types";
import {ICase, ICaseComment, ICaseStep} from "../entities";


export const caseTrakingApi = createApi({
    reducerPath: 'caseApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Cases', 'Agents', 'CaseSteps', 'CaseComments', 'StepComments'],
    endpoints: (builder) => (
        {
            getCases: builder.query<IJsonResourceResponse<ICase, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/cases?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Cases' as const,
                                    id,
                                }
                            )),
                            {type: 'Cases', id: 'LIST'},
                        ]
                        : [{type: 'Cases', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<ICase, 'multiple'>) => results,
            }),
            getCaseSteps: builder.query<IJsonResourceResponse<ICaseStep, 'multiple'>, GetItemsQueryArgs & {
                parentId: number
            }>({
                query: ({page, limit, searchTerm, parentId}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/cases/${parentId}/steps?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'CaseSteps' as const,
                                    id,
                                }
                            )),
                            {type: 'CaseSteps', id: 'LIST'},
                        ]
                        : [{type: 'CaseSteps', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<ICaseStep, 'multiple'>) => results,
            }),
            getCaseComments: builder.query<IJsonResourceResponse<ICaseComment, 'multiple'>, GetItemsQueryArgs & {
                parentId: number
            }>({
                query: ({page, limit, searchTerm, parentId}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/cases/${parentId}/comments?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'CaseComments' as const,
                                    id,
                                }
                            )),
                            {type: 'CaseComments', id: 'LIST'},
                        ]
                        : [{type: 'CaseComments', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<ICaseComment, 'multiple'>) => results,
            }),
            getCaseStepComments: builder.query<IJsonResourceResponse<ICaseComment, 'multiple'>, GetItemsQueryArgs & {
                parentId: number,
                stepId: number
            }>({
                query: ({page, limit, searchTerm, parentId, stepId}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/cases/${parentId}/steps/${stepId}/comments?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'StepComments' as const,
                                    id,
                                }
                            )),
                            {type: 'StepComments', id: 'LIST'},
                        ]
                        : [{type: 'StepComments', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<ICaseComment, 'multiple'>) => results,
            }),
            getCase: builder.query<ICase, GetItemQueryArgs>({
                query: (id) => {
                    return {
                        url: `/cases/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Cases', id}],
                transformResponse: (result: IJsonResourceResponse<ICase, 'single'>) => result.item!,
            }),
            createCase: builder.mutation<ICase, PostItemArgs<ICase> & { parentId: number }>({
                query: ({data, parentId: conversationId}) => {
                    return {
                        url: `/conversations/${conversationId}/cases`,
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Cases', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<ICase, 'single'>) => result.item!,
            }),
            createCaseStep: builder.mutation<ICaseStep, PostItemArgs<ICaseStep> & { parentId: number }>({
                query: ({data, parentId: caseId}) => {
                    return {
                        url: `/cases/${caseId}/steps`,
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'CaseSteps', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<ICaseStep, 'single'>) => result.item!,
            }),
            addCaseComment: builder.mutation<ICaseComment, PostItemArgs<ICaseComment> & { parentId: number }>({
                query: ({data, parentId: caseId}) => {
                    return {
                        url: `/cases/${caseId}/comments`,
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                // invalidatesTags: [{type: 'CaseComments', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<ICaseComment, 'single'>) => result.item!,
            }),
            addCaseStepComment: builder.mutation<ICaseComment, PostItemArgs<ICaseComment> & {
                parentId: number,
                stepId: number
            }>({
                query: ({data, parentId: caseId, stepId}) => {
                    return {
                        url: `/cases/${caseId}/steps/${stepId}/comments`,
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                // invalidatesTags: [{type: 'StepComments', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<ICaseComment, 'single'>) => result.item!,
            }),
            updateCase: builder.mutation<ICase, PutItemArgs<ICase>>({
                query: ({data, id}) => {
                    return {
                        url: `/cases/${id}`,
                        method: 'PUT',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Cases', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<ICase, 'single'>) => result.item!,
            }),
            toggleCaseStatus: builder.mutation<ICase, Omit<PutItemArgs<ICase>, 'data'>>({
                query: ({id}) => {
                    return {
                        url: `/cases/${id}/toggle`,
                        method: 'PATCH',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Cases', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<ICase, 'single'>) => result.item!,
            }),
            deleteCase: builder.mutation<ICase, DeleteItemArgs>({
                query: ({id}) => {
                    return {
                        url: `/cases/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Cases', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetCasesQuery,
    useGetCaseStepsQuery,
    useGetCaseCommentsQuery,
    useGetCaseStepCommentsQuery,
    useGetCaseQuery,
    useCreateCaseMutation,
    useAddCaseCommentMutation,
    useAddCaseStepCommentMutation,
    useCreateCaseStepMutation,
    useUpdateCaseMutation,
    useToggleCaseStatusMutation,
    useDeleteCaseMutation,
} = caseTrakingApi;
