import styled from "@emotion/styled";
import {Save} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {Box, Button, FormControl} from "@mui/material";
import {SideModal} from "../SideModal";
import {ICrudFormProps} from "./props";


export const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    maxWidth: '100%!important',
    width: '100%',
});

export const FormControlWrapper = styled(FormControl)({
    margin: '0.5rem 0',
});

export const FormAction = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1rem',
    '& > button': {
        marginLeft: '0.5rem',
    },
});

const CrudForm = ({
                      open,
                      onClose,
                      isSubmitting,
                      title,
                      onSubmit,
                      onRenderCancelButton,
                      onRenderSubmitButton,
                      isEdition,
                      children,
                      width,
                      childrenAfterFormActions,
                      submitButtonLabel,
                      cancelButtonLabel,
                      submitButtonIcon,
                      cancelButtonIcon,
                  }: ICrudFormProps) => {

    return (
        <SideModal
            {...width && {width}}
            open={open}
            onClose={onClose}
            title={title}
        >
            <Form
                style={{
                    maxWidth: '100%!important',
                }}
                onSubmit={onSubmit}>
                {children}
                <FormAction>
                    {undefined !== onRenderCancelButton ? onRenderCancelButton() : (
                        <Button
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                            }}
                            disabled={isSubmitting}
                            onClick={onClose}>
                            {cancelButtonIcon || <CloseIcon/>}
                            {cancelButtonLabel || 'Annuler'}
                        </Button>
                    )}
                    {undefined !== onRenderSubmitButton ? onRenderSubmitButton() : (
                        <Button
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem',
                            }}
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}>
                            {isEdition ? (
                                <>
                                    <Save/>
                                    Enregistrer
                                </>
                            ) : (
                                <>
                                    {submitButtonIcon || <AddIcon/>}
                                    {submitButtonLabel || 'Ajouter'}
                                </>
                            )}
                        </Button>
                    )}
                </FormAction>
                {childrenAfterFormActions}
            </Form>
        </SideModal>
    );
}

export default CrudForm;

