import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {initialPresenceStatusState} from "./state";
import {IPresence} from "./types";

export const presenceStatusSlice = createSlice({
    name: 'presenceStatus',
    initialState: initialPresenceStatusState,
    reducers: {
        updatePresenceStatus: (state, action: PayloadAction<IPresence>) => {
            state.presences = action.payload;
        }
    }
})

export const {updatePresenceStatus} = presenceStatusSlice.actions;

export default presenceStatusSlice.reducer;
