import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {DeleteItemArgs, GetItemsQueryArgs, IJsonResourceResponse, PutItemArgs} from "../../share/types";
import {IConversationRequest} from "../entities";


export const conversationRequestApi = createApi({
    reducerPath: 'conversationRequestApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['ConversationRequests'],
    endpoints: (builder) => (
        {
            getConversationRequests: builder.query<IJsonResourceResponse<IConversationRequest, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/conversation-requests?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'ConversationRequests' as const,
                                    id,
                                }
                            )),
                            {type: 'ConversationRequests', id: 'LIST'},
                        ]
                        : [{type: 'ConversationRequests', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IConversationRequest, 'multiple'>) => results,
            }),

            getArchivedConversations: builder.query<IJsonResourceResponse<IConversationRequest, 'multiple'>, GetItemsQueryArgs & {
                filters?: string
            }>({
                query: ({page, limit, searchTerm, filters}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    const qfilters = filters ? `&filters=${filters}` : '';

                    return {
                        url: `/conversations/archives?${pageQuery}${limitQuery}${search}${qfilters}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'ConversationRequests' as const,
                                    id,
                                }
                            )),
                            {type: 'ConversationRequests', id: 'LIST'},
                        ]
                        : [{type: 'ConversationRequests', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IConversationRequest, 'multiple'>) => results,
            }),

            getConversationRequest: builder.query<IConversationRequest, number>({
                query: (id) => {
                    return {
                        url: `/conversation-requests/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, id) => [{type: 'ConversationRequests', id}],
                transformResponse: (result: IJsonResourceResponse<IConversationRequest, 'single'>) => result.item!,
            }),
            resumeConversationRequest: builder.mutation<IConversationRequest, PutItemArgs<IConversationRequest>>({
                query: ({data, id}) => {
                    return {
                        url: `/conversation-requests/${id}`,
                        method: 'PUT',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'ConversationRequests', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IConversationRequest, 'single'>) => result.item!,
            }),
            approveConversationRequest: builder.mutation<IConversationRequest, Omit<PutItemArgs<IConversationRequest>, 'data'>>({
                query: ({id}) => {
                    return {
                        url: `/conversation-requests/${id}/approve`,
                        method: 'PATCH',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'ConversationRequests', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IConversationRequest, 'single'>) => result.item!,
            }),
            rejectConversationRequest: builder.mutation<IConversationRequest, Omit<PutItemArgs<IConversationRequest>, 'data'>>({
                query: ({id}) => {
                    return {
                        url: `/conversation-requests/${id}/reject`,
                        method: 'PATCH',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'ConversationRequests', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IConversationRequest, 'single'>) => result.item!,
            }),
            deleteConversationRequest: builder.mutation<IConversationRequest, DeleteItemArgs>({
                query: ({id}) => {
                    return {
                        url: `/conversation-requests/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'ConversationRequests', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetConversationRequestsQuery,
    useGetArchivedConversationsQuery,
    useGetConversationRequestQuery,
    useResumeConversationRequestMutation,
    useApproveConversationRequestMutation,
    useRejectConversationRequestMutation,
    useDeleteConversationRequestMutation,
} = conversationRequestApi;
