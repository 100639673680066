import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {toFormData} from "../../../utils";
import {GetItemsQueryArgs, IJsonResourceResponse, PostItemArgs} from "../../share/types";
import {IMessage} from "../entities";


export const messageApi = createApi({
    reducerPath: 'messageApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Messages'],
    endpoints: (builder) => (
        {
            getConversationMessages: builder.query<IJsonResourceResponse<IMessage, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm, parentId}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/conversations/${parentId}/messages?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Messages' as const,
                                    id,
                                }
                            )),
                            {type: 'Messages', id: 'LIST'},
                        ]
                        : [{type: 'Messages', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IMessage, 'multiple'>) => results,
            }),
            createMessage: builder.mutation<IMessage, PostItemArgs<IMessage>>({
                query: ({data, parentId}) => {
                    return {
                        url: `/conversations/${parentId}/messages`,
                        method: 'POST',
                        credentials: 'include',
                        data: toFormData(data),
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    };
                },
                // invalidatesTags: [{type: 'Messages', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IMessage, 'single'>) => result.item!,
            }),
        }
    ),
});

export const {
    useGetConversationMessagesQuery,
    useCreateMessageMutation,
} = messageApi;
