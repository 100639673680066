import React from 'react';
import {Box, Divider, Typography} from '@mui/material';
import {HorizontalSeparatorWithTextProps} from "./props";

export const HorizontalSeparatorWithText = ({text, icon, sx}: HorizontalSeparatorWithTextProps) => {
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            margin: '16px 0',
            ...sx
        }}>
            <Divider
                sx={{
                    flexGrow: 1,
                    borderColor: 'primary.main',
                    opacity: 0.3,
                }}
            />
            <Typography variant="subtitle1" style={{margin: '0 16px'}}>
                {text}
            </Typography>
            <Divider
                sx={{
                    flexGrow: 1,
                    borderColor: 'primary.main',
                    opacity: 0.3,
                }}/>
        </Box>
    );
};
