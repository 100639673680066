import {CircularProgress} from '@mui/material'
import React from 'react'


export default function CircularLoader() {
    return <CircularProgress sx={{
        maxWidth: '20',
        minHeight: '20px',
        height: 'auto',
        mr: 1.5
    }}/>
}
