import {createApi} from '@reduxjs/toolkit/query/react'
import {AUTH_BASE_URL} from "../../../../config";
import axiosBaseQuery from "../../../../redux/rtk/axiosBaseQuery";
import {PostQueryArgs} from "../../../../types";
import {ILoginRequest, ILoginResponse} from "../../entities";
import {profileApi} from "./profileApi";


export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: axiosBaseQuery({baseUrl: AUTH_BASE_URL}),
    tagTypes: ['Auth'],
    endpoints: (builder) => ({
        login: builder.mutation<ILoginResponse, Omit<PostQueryArgs<ILoginRequest>, 'parentId'>>({
            query: ({data}) => {
                return {
                    url: `/login`,
                    method: 'POST',
                    credentials: 'include',
                    data
                }
            },
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                await queryFulfilled
                await dispatch(profileApi.endpoints.getProfile.initiate(null))
            }
        }),
    }),
})

export const {
    useLoginMutation,
} = authApi