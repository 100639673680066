import {Box} from "@mui/material";
import React, {useCallback} from "react";
import {TextLoader} from "../../../components/Loader";
import {DataGrid, GridToolBar} from "../../../lib/ui/src/components/DataGrid";
import {IDataGridAction, IDataGridColumn, IGridToolBarFuncProps} from "../../../lib/ui/src/components/DataGrid/props";
import {dateFormat, toPythonDate} from "../../../utils";
import {IAgentStatsData} from "../entities";
import {useGetAgentConversationsStatsByPeriodQuery} from "../services/statsApi";
import {DoneAllOutlined, PendingActionsOutlined} from "@mui/icons-material";
import {useDateFilter} from "../../../lib/ui/src/components/DateFilter/hooks";
import {DateFilter} from "../../../lib/ui/src/components/DateFilter";


export const userColumns: IDataGridColumn<IAgentStatsData>[] = [
    {
        name: 'id',
        numeric: true,
        disablePadding: false,
        label: '#',
    },
    {
        name: 'username',
        numeric: false,
        disablePadding: false,
        label: 'Nom d\'utilisateur',
    },
    {
        name: 'lastname',
        numeric: false,
        disablePadding: false,
        label: 'Nom & Prénom(s)',
        render: ({lastname, firstname}) => {
            return `${lastname} ${firstname}`
        }
    },
    {
        name: 'done_conversations',
        numeric: false,
        disablePadding: false,
        label: 'Conversations traitées',
        render: ({done_conversations}) => {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.5rem'
                    }}
                >
                    <DoneAllOutlined/>
                    {done_conversations}
                </Box>
            )
        }
    },
    {
        name: 'active_conversations',
        numeric: false,
        disablePadding: false,
        label: 'Conversations en cours',
        render: ({active_conversations}) => {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '.5rem'
                    }}
                >
                    <PendingActionsOutlined/>
                    {active_conversations}
                </Box>
            )
        }
    },
    {
        name: 'messages',
        numeric: false,
        disablePadding: false,
        label: 'Messages',
    },
    {
        name: 'dates',
        numeric: false,
        disablePadding: false,
        label: 'Intervalle de dates',
    },
    {
        name: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
    }
]
export const AgentConversationsStats = () => {
    const {startDate, endDate, period, handlePeriodChange, setStartDate, setEndDate} = useDateFilter({
        initialPeriod: 'current_year'
    });
    const {isLoading, data} = useGetAgentConversationsStatsByPeriodQuery({
        startDate: toPythonDate(startDate),
        endDate: toPythonDate(endDate)
    });

    const renderDateFilters = useCallback(() => {
        return <DateFilter
            startDate={startDate}
            endDate={endDate}
            period={period}
            handlePeriodChange={handlePeriodChange}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
        />;
    }, [endDate, handlePeriodChange, period, setEndDate, setStartDate, startDate]);

    const renderActions = useCallback((user: IAgentStatsData) => {
        return [] as IDataGridAction<IAgentStatsData>[]
    }, [])

    const renderGridToolbar = useCallback(({selectedRowsIds}: IGridToolBarFuncProps<IAgentStatsData>) => {
        return (
            <GridToolBar
                selectedItems={selectedRowsIds || []}
                title={`Statistiques des conversations des agents du ${dateFormat(startDate)} au ${dateFormat(endDate)}`}
            >
                {renderDateFilters()}
            </GridToolBar>
        )
    }, [renderDateFilters, startDate, endDate])


    if (isLoading) {
        return <TextLoader/>
    }

    return (
        <>
            {data && (
                <DataGrid<IAgentStatsData>
                    columns={userColumns}
                    rows={data!.items!}
                    gridToolBar={renderGridToolbar}
                    // gridFooter={renderGridFooter()}
                    actions={renderActions}
                />
            )}
        </>
    )
}
