import {Edit} from "@mui/icons-material";
import React, {useCallback, useState} from 'react'
import {useParams} from "react-router-dom";
import {TextLoader} from "../../../../../components/Loader";
import {
    DataGrid,
    DEFAULT_PAGE,
    DEFAULT_ROWS_PER_PAGE,
    GridFooter,
    GridToolBar
} from "../../../../../lib/ui/src/components/DataGrid";
import {
    IDataGridAction,
    IDataGridColumn,
    IGridToolBarFuncProps
} from "../../../../../lib/ui/src/components/DataGrid/props";
import {Page, RowPerPage} from "../../../../../lib/ui/src/components/DataGrid/types";
import {dateFormat} from "../../../../../utils";
import ResponseForm from "../../../components/ResponseForm";
import {IResponse} from "../../../entities";
import {useGetQuestionResponsesQuery} from "../../../services/responseApi";


const columns: IDataGridColumn<IResponse>[] = [
    {
        name: 'id',
        label: '#',
        numeric: true,
        disablePadding: false,
    },
    {
        name: 'short_explaination',
        label: 'Réponse',
        numeric: false,
        disablePadding: false,
    },
    {
        name: 'long_explanation',
        label: 'Détails réponse',
        numeric: false,
        disablePadding: false,
    },
    {
        name: 'question_id',
        numeric: false,
        disablePadding: false,
        label: 'Nº question',
        render: ({question_id}) => question_id ? `#${question_id}` : 'N/A'
    },
    {
        name: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Créée le',
        render: ({created_at}) => created_at ? dateFormat(created_at) : 'N/A'
    },
    {
        name: 'updated_at',
        numeric: false,
        disablePadding: false,
        label: 'Modifiée le',
        render: ({updated_at}) => updated_at ? dateFormat(updated_at) : 'N/A'
    },
    {
        name: 'created_by',
        numeric: false,
        disablePadding: false,
        label: 'Créée par',
        render: ({created_by}) => created_by ? created_by : 'N/A'
    },
    {
        name: 'updated_by',
        numeric: false,
        disablePadding: false,
        label: 'Modifiée par',
        render: ({updated_by}) => updated_by ? updated_by : 'N/A'
    },
    {
        name: 'actions',
        label: 'Actions',
        numeric: false,
        disablePadding: false,
    }
]

const ResponsesPage = () => {
    const {id: parentId} = useParams()
    const [page, setPage] = useState<Page>(DEFAULT_PAGE);
    const [rowsPerPage, setRowsPerPage] = useState<RowPerPage>(DEFAULT_ROWS_PER_PAGE);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [editResponse, setEditResponse] = useState<IResponse | undefined>(undefined);
    const {isLoading, data} = useGetQuestionResponsesQuery({
        page,
        limit: rowsPerPage,
        searchTerm: search,
        parentId: parseInt(parentId!)
    })
    const closeFormDialog = () => {
        setOpen(false);
    }
    const openFormDialog = () => {
        setOpen(true);
    }

    const renderActions = useCallback((info: IResponse) => {
        return [
            {
                label: 'Editer',
                icon: <Edit/>,
                color: 'warning',
                tooltip: 'Editer',
                onClick: () => {
                    setEditResponse(info);
                    openFormDialog();
                }
            },
        ] as IDataGridAction<IResponse>[]
    }, [])

    const renderGridToolbar = useCallback(({selectedRowsIds}: IGridToolBarFuncProps<IResponse>) => {
        return (
            <GridToolBar
                selectedItems={selectedRowsIds || []}
                title={`Liste des réponses`}
                onSearch={(value) => {
                    setSearch(value)
                }}
                onAdd={() => {
                    setEditResponse(undefined);
                    openFormDialog()
                }}
            />
        )
    }, [])

    const renderGridFooter = useCallback(() => {
        return (
            <GridFooter
                total={data!.metadata!.total}
                pages={data!.metadata!.pages}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={newPage => setPage(newPage)}
                onRowsPerPageChange={newPageSize => setRowsPerPage(newPageSize)}
            />
        )
    }, [data, page, rowsPerPage])

    if (isLoading) {
        return <TextLoader/>
    }
    return (
        <>
            {data && (
                <DataGrid<IResponse>
                    columns={columns}
                    rows={data.items!}
                    gridToolBar={renderGridToolbar}
                    gridFooter={renderGridFooter()}
                    actions={renderActions}
                />
            )}
            <ResponseForm
                question_id={parseInt(parentId!, 10)}
                open={open}
                onClose={closeFormDialog}
                response={editResponse}
            />
        </>
    )
}

export default ResponsesPage;
