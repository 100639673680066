import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {DeleteItemArgs, GetItemsQueryArgs, IJsonResourceResponse, PostItemArgs, PutItemArgs} from "../../share/types";
import {IMessageTemplate} from "../entities";


export const messageTemplateApi = createApi({
    reducerPath: 'messageTemplateApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['MessageTemplates'],
    endpoints: (builder) => (
        {
            getMessageTemplates: builder.query<IJsonResourceResponse<IMessageTemplate, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/templates/messages?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'MessageTemplates' as const,
                                    id,
                                }
                            )),
                            {type: 'MessageTemplates', id: 'LIST'},
                        ]
                        : [{type: 'MessageTemplates', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IMessageTemplate, 'multiple'>) => results,
            }),
            createMessageTemplate: builder.mutation<IMessageTemplate, PostItemArgs<IMessageTemplate>>({
                query: ({data}) => {
                    return {
                        url: '/templates/messages',
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'MessageTemplates', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IMessageTemplate, 'single'>) => result.item!,
            }),
            updateMessageTemplate: builder.mutation<IMessageTemplate, PutItemArgs<IMessageTemplate>>({
                query: ({data, id}) => {
                    return {
                        url: `/templates/messages/${id}`,
                        method: 'PUT',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'MessageTemplates', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IMessageTemplate, 'single'>) => result.item!,
            }),
            deleteMessageTemplate: builder.mutation<IMessageTemplate, DeleteItemArgs>({
                query: ({id}) => {
                    return {
                        url: `/templates/messages/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'MessageTemplates', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetMessageTemplatesQuery,
    useCreateMessageTemplateMutation,
    useUpdateMessageTemplateMutation,
    useDeleteMessageTemplateMutation,
} = messageTemplateApi;
