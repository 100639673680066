import {Encrypt} from "./Services/Encrypt";


// use Encrypt to encrypt and decrypt data

export class SecureStorage {
    private readonly encryptor: Encrypt;
    private reactiveStorage: ReactiveStorage

    constructor(secureKey: string = '@@secureKey@@') {
        this.encryptor = new Encrypt(
            secureKey
        );
        this.reactiveStorage = new ReactiveStorage(
            this.encryptor
        );
    }

    public remember(key: string, data: any, watch: boolean = false) {
        localStorage.setItem(key, this.encryptor.encrypt(JSON.stringify(data)))
        if (watch) {
            this.reactiveStorage.dispatchStorageEvent()
        }
    }

    public get<T>(key: string): T | null {
        if (this.has(key)) {
            try {
                const data = JSON.parse(this.encryptor.decrypt(localStorage.getItem(key) || ''))
                if (data) {
                    return data as T
                }
            } catch (e) {
                console.error('Impossible de lire les données')
                localStorage.clear()
            }
        }
        return null
    }

    public forget(key: string) {
        if (this.has(key)) {
            localStorage.removeItem(key)
        }
    }

    public has(key: string) {
        return localStorage.getItem(key) !== null
    }

    watch<T>(key: string, callback: (data: T | null) => void) {
        window.addEventListener('storage', (e) => {
            callback(
                this.get<T>(key) || null
            )
        }, false)
    }
}


export class ReactiveStorage {

    private readonly storageEvent;
    private encryptor: Encrypt;

    constructor(encryptor: Encrypt) {
        this.encryptor = encryptor;

        this.storageEvent = document.createEvent(
            'StorageEvent'
        );

        this.storageEvent.initStorageEvent(
            'storage',
            false,
            false,
            null as any,
            null as any,
            null as any,
            null as any,
            window.localStorage
        );
    }

    public dispatchStorageEvent() {
        window.dispatchEvent(this.storageEvent);
    }
}
