import {GroupsOutlined, SupervisedUserCircleSharp} from "@mui/icons-material";
import {lazy} from "react";
import {IRouteType} from "../../../types";
import {GestionRoutesType} from "./types";


const UsersPage = lazy(() => import("../pages/users-page"));
const AgentsPage = lazy(() => import("../pages/agents-page"));

export const userRoutes: GestionRoutesType = {
    users: {
        path: '/users',
        component: <UsersPage/>,
        title: 'Utilisateurs',
        icon: <GroupsOutlined/>,
        permission: 'ROLE_ADMIN',
    },
    agents: {
        path: '/agents',
        component: <AgentsPage/>,
        title: 'CB & Agents',
        icon: <SupervisedUserCircleSharp/>,
        permission: 'ROLE_ADMIN',
    },
}


export const userSidebarRoutes: Omit<IRouteType, 'component'>[] = Object.entries(userRoutes)
    .map(([_, {component, ...rest}]) => (
        {
            ...rest,
        }
    ))
