import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {GetItemQueryArgs, GetItemsQueryArgs, IJsonResourceResponse, PatchItemArgs} from "../../share/types";
import {IConversation, IConversationStats, IMessageAttachment, ITransfertConversation} from "../entities";


export const conversationApi = createApi({
    reducerPath: 'conversationApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Conversations', 'Attachments', 'Stats'],
    endpoints: (builder) => (
        {
            getConversations: builder.query<IJsonResourceResponse<IConversation, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/conversations?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Conversations' as const,
                                    id,
                                }
                            )),
                            {type: 'Conversations', id: 'LIST'},
                        ]
                        : [{type: 'Conversations', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IConversation, 'multiple'>) => results,
            }),
            getConversation: builder.query<IConversation, GetItemQueryArgs>({
                query: ({id}) => {
                    return {
                        url: `/conversations/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Conversations', id}],
                transformResponse: (result: IJsonResourceResponse<IConversation, 'single'>) => result.item!
            }),
            archiveConversation: builder.mutation<IConversation, PatchItemArgs<IConversation>>({
                query: ({id, data}) => {
                    return {
                        url: `/conversations/${id}/archive`,
                        method: 'PATCH',
                        credentials: 'include',
                        data: data
                    };
                },
                invalidatesTags: (result, error, {id}) => [{type: 'Conversations', id}],
                transformResponse: (result: IJsonResourceResponse<IConversation, 'single'>) => result.item!
            }),
            getConversationAttachments: builder.query<IJsonResourceResponse<IMessageAttachment, 'multiple'>, Omit<GetItemsQueryArgs, 'searchTerm' | 'limit' | 'page'>>({
                query: ({parentId}) => {
                    return {
                        url: `/conversations/${parentId}/attachments`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Attachments' as const,
                                    id,
                                }
                            )),
                            {type: 'Attachments', id: 'LIST'},
                        ]
                        : [{type: 'Attachments', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IMessageAttachment, 'multiple'>) => results,
            }),
            getConversationStats: builder.query<IConversationStats, Omit<GetItemQueryArgs, 'parentId'>>({
                query: ({id}) => {
                    return {
                        url: `/conversations/${id}/stats`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Stats', id}],
                transformResponse: (result: IJsonResourceResponse<IConversationStats, 'single'>) => result.item!
            }),
            transfertConversation: builder.mutation<IConversation, PatchItemArgs<ITransfertConversation>>({
                query: ({id, data}) => {
                    return {
                        url: `/conversations/transfers/${id}`,
                        method: 'PATCH',
                        credentials: 'include',
                        data: data
                    };
                },
                invalidatesTags: (result, error, {id}) => [{type: 'Conversations', id}],
                transformResponse: (result: IJsonResourceResponse<IConversation, 'single'>) => result.item!
            }),
        }
    ),
});

export const {
    useGetConversationsQuery,
    useGetConversationQuery,
    useGetConversationStatsQuery,
    useGetConversationAttachmentsQuery,
    useArchiveConversationMutation,
    useTransfertConversationMutation,
} = conversationApi;
