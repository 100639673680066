import {SetStateAction, useCallback, useEffect, useState} from "react";
import {CURRENT_DATE} from "../../../../../../constants";
import {getCurrentPeriod} from "../../../../../../utils";

export const PREVIOUS_MONTH_DATE = new Date(CURRENT_DATE.setMonth(CURRENT_DATE.getMonth() - 1));
export type Period = '1_week' | '30_days' | '1_year' | 'current_week' | 'current_month' | 'current_year' | 'custom';
export type DateFilterProps = {
    initialPeriod?: Period;
}
export const useDateFilter = ({initialPeriod = '1_week'}: DateFilterProps = {}) => {
    const [startDate, setStartDate] = useState(PREVIOUS_MONTH_DATE);
    const [endDate, setEndDate] = useState(new Date());
    const [period, setPeriod] = useState<Period>(initialPeriod);

    // update start and end dates when period changes
    useEffect(() => {
        let newStartDate;
        switch (period) {
            case '1_week':
                newStartDate = new Date();
                newStartDate.setDate(newStartDate.getDate() - 7);
                setStartDate(newStartDate);
                setEndDate(new Date());
                break;
            case '30_days':
                newStartDate = new Date();
                newStartDate.setDate(newStartDate.getDate() - 30);
                setStartDate(newStartDate);
                setEndDate(new Date());
                break;
            case '1_year':
                newStartDate = new Date();
                newStartDate.setFullYear(newStartDate.getFullYear() - 1);
                setStartDate(newStartDate);
                setEndDate(new Date());
                break;
            case 'current_week':
                const {startDate, endDate} = getCurrentPeriod('current_month');
                setStartDate(startDate);
                setEndDate(endDate);
                break;
            case 'current_month':
                const {startDate: startDateMonth, endDate: endDateMonth} = getCurrentPeriod('current_month');
                setStartDate(startDateMonth);
                setEndDate(endDateMonth);
                break;
            case 'current_year':
                const {startDate: startDateYear, endDate: endDateYear} = getCurrentPeriod('current_year');
                setStartDate(startDateYear);
                setEndDate(endDateYear);
                break;
            case 'custom':
                // set dates to null or any default values you like
                setStartDate(PREVIOUS_MONTH_DATE);
                setEndDate(new Date());
                break;
            default:
                break;
        }
    }, [period]);

    const handlePeriodChange = useCallback((event: { target: { value: SetStateAction<string>; }; }) => {
        setPeriod(event.target.value as Period);
    }, []);

    return {startDate, endDate, period, handlePeriodChange, setStartDate, setEndDate};
}
