import {Box, MenuItem, Pagination, Select, SelectChangeEvent, Typography} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import {DEFAULT_ROWS_PER_PAGE, ROWS_PER_PAGE_OPTIONS} from "../constants";
import {IGridFooterProps} from "../props";
import {Page, RowPerPage} from "../types";
import './css/index.scss';


const GridFooter = ({
                        total,
                        pages,
                        page,
                        rowsPerPage,
                        onPageChange,
                        onRowsPerPageChange,
                        children
                    }: IGridFooterProps) => {
    const [perPage, setPerPage] = useState<RowPerPage>(rowsPerPage || DEFAULT_ROWS_PER_PAGE);
    const [currentPage, setCurrentPage] = useState<Page>(page);

    const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
        onPageChange(page);
    };

    const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
        setPerPage(parseInt(String(event.target.value), 10) as RowPerPage);
        onRowsPerPageChange(parseInt(String(event.target.value), 10) as RowPerPage);
    };

    return (
        <div className="grid-footer">
            <div className="col-l">
                {children}
            </div>
            <div className="col-e">
                <div className="grid-footer__pagination">
                    <Pagination
                        count={pages}
                        page={currentPage}
                        onChange={handlePageChange}
                        showFirstButton
                        showLastButton
                    />
                </div>
                <div className="grid-footer__page-size">
                    <Box
                        sx={{
                            minWidth: 120,
                            display: 'flex',
                            gap: 2,
                            alignItems: 'center',
                        }}
                    >
                        {total > 0 && (
                            <Typography component={'label'}>
                                Total: {total} | Page: {currentPage}
                            </Typography>
                        )}

                        <Typography component={'label'}>
                            Par page
                        </Typography>
                        <Select
                            inputProps={{
                                className: 'grid-footer__page-size__select',
                            }}
                            variant={'standard'}
                            size="small"
                            labelId="page-size-label"
                            id="page-size"
                            value={perPage}
                            onChange={handlePageSizeChange}
                            defaultValue={rowsPerPage}
                            sx={{
                                '&::before': {
                                    borderBottom: 'none',
                                }
                            }}
                        >
                            {ROWS_PER_PAGE_OPTIONS.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </div>
            </div>
        </div>
    );
}

export default GridFooter;
