import {Box, LinearProgress} from '@mui/material'
import React from 'react'
import {styled} from "@mui/material/styles";


const LinearLoaderRoot = styled(Box)(({theme}) => ({
    width: '100%',
    position: 'absolute',
    top: '26px',
    left: 0,
    padding: 0,
    [theme.breakpoints.down('sm')]: {
        top: '33px'
    },
    [theme.breakpoints.up('sm')]: {
        top: '26px'
    }
}))

export default function LinearLoader() {
    return (
        <LinearLoaderRoot>
            <LinearProgress/>
        </LinearLoaderRoot>
    )
}
