import {zodResolver} from "@hookform/resolvers/zod/dist/zod";
import {Refresh} from "@mui/icons-material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, TextField} from "@mui/material";

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {TextLoader} from "../../../components/Loader";
import {ToastEnum} from "../../../constants";
import useError from "../../../hooks/useError";
import {useTabNavigator} from "../../../hooks/useTabNavigator";
import {useToast} from "../../../hooks/useToast";
import TabForm from "../../../lib/ui/src/components/Form/TabForm";
import {StyledIconButton} from "../../../lib/ui/src/components/StyledIconButton";
import {IQuestion} from "../entities";
import ResponsesPage from "../pages/guides/responses/responses-page";
import {QuestionFormSchema, QuestionFormType} from "../schemas";
import {useCreateQuestionMutation, useGetQuestionQuery, useUpdateQuestionMutation} from "../services/questionApi";
import {useGetRubricsQuery} from "../services/rubricApi";

const QuestionForm = () => {

    const {id: parentId} = useParams()

    const {data: rubrics, isLoading: isRubricsLoading, refetch: refetchRubrics} = useGetRubricsQuery({
        page: 1,
        limit: 1000,
    })

    const {data: question, isLoading} = useGetQuestionQuery({
        id: parseInt(parentId!),
    }, {skip: !parentId})

    const {activeTab, onChangeTab} = useTabNavigator();

    const [
        createQuestion, {
            data,
            isLoading: isSubmitting,
            isError: hasCreationError,
            error: creationError,
            isSuccess: isCreatedWithSuccess
        }
    ] = useCreateQuestionMutation()
    const [
        updateQuestion, {
            data: updatedData,
            isLoading: isEditSubmitting,
            isError: hasUpdatingError,
            error: updateError,
            isSuccess: isUpdatedWithSuccess
        }
    ] = useUpdateQuestionMutation()
    const {
        register,
        formState: {errors, isSubmitSuccessful},
        setValue,
        reset,
        handleSubmit,
        control,
    } = useForm<QuestionFormType>({
        resolver: zodResolver(QuestionFormSchema),
    });

    const {hasError, getError, setErrors} = useError(errors)
    const navigate = useNavigate()
    const {showToast} = useToast()

    useEffect(() => {
        if (isSubmitSuccessful && isCreatedWithSuccess && !question) {
            reset();
        }
    }, [isCreatedWithSuccess, isSubmitSuccessful, reset, question]);

    useEffect(() => {
        setErrors(errors)
    }, [errors, setErrors])

    useEffect(() => {
        if (question) {
            setValue('title', question.title);
            setValue('variants', question.variants);
        }
    }, [setValue, question])

    useEffect(() => {
        if (isCreatedWithSuccess && data && !parentId) {
            navigate(`./../${data.id}/edit`)
        }
    }, [data, isCreatedWithSuccess, navigate, parentId])

    useEffect(() => {
        if (isCreatedWithSuccess) {
            showToast('La question a été créée avec succès', ToastEnum.success)
        }
        if (hasCreationError && creationError) {
            showToast('Une erreur est survenue lors de la création de la question', ToastEnum.error)
            setErrors((
                creationError as any
            ).data.violations ?? {})
        }
        if (isUpdatedWithSuccess) {
            showToast('La question a été modifiée avec succès', ToastEnum.success)
        }
        if (hasUpdatingError && updateError) {
            showToast('Une erreur est survenue lors de la modification de la question', ToastEnum.error)
            setErrors((
                updateError as any
            ).data.violations ?? {})
        }
    }, [creationError, updatedData, hasCreationError, hasUpdatingError, isCreatedWithSuccess, isUpdatedWithSuccess, setErrors, updateError, showToast])

    const onFormSubmit = (data: QuestionFormType) => {
        if (question) {
            updateQuestion({
                data: data as unknown as IQuestion,
                id: question!.id as number
            })
        } else {
            createQuestion({
                data: data as unknown as Partial<IQuestion>
            })
        }
    }

    if (isLoading || isRubricsLoading) {
        return <TextLoader/>
    }

    return (
        <Box sx={{
            width: '100%',
            background: 'rgb(248, 248, 251)',
            borderRadius: '20px',
        }}
        >
            <TabContext value={activeTab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', pt: 1}}>
                    <TabList
                        onChange={onChangeTab} aria-label="Gestion des questions">
                        <Tab label={`${question ? 'Edition' : 'Création '}`} value="1"/>
                        {question && <Tab label="Réponses associées" value="2"/>}
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <TabForm
                        onCancel={() => {
                            if (question) {
                                navigate('./../../')
                            } else {
                                navigate('./../')
                            }
                        }}
                        onSubmit={handleSubmit(onFormSubmit)}
                        isSubmitting={isSubmitting || isEditSubmitting}
                        isEdition={!!question}
                    >
                        <Box sx={{
                            my: 2,
                            maxWidth: 500,
                            width: '100%'
                        }}>
                            <TextField
                                fullWidth
                                error={hasError('title')}
                                type="text"
                                variant="outlined"
                                id="title"
                                label="Titre"
                                required
                                helperText={getError('title')}
                                {...register('title')}
                            />
                        </Box>
                        {rubrics && (
                            <Box sx={{
                                my: 2,
                                maxWidth: 500,
                                width: '100%',
                                display: 'flex',
                                gap: '.5rem'
                            }}>
                                <FormControl
                                    fullWidth
                                >
                                    <InputLabel
                                        variant={'outlined'}
                                        id="genre"
                                    >
                                        Rubrique
                                    </InputLabel>
                                    <Controller
                                        name="rubric_id"
                                        control={control}
                                        defaultValue={question?.rubric_id ? String(question.rubric_id) : ""}
                                        render={({field}) => (
                                            <Select
                                                {...field}
                                                error={hasError('rubric_id')}
                                                labelId="rubric_id"
                                                label="Rubrique"
                                                disabled={isSubmitting || isEditSubmitting}
                                                input={<OutlinedInput label="Rubrique"/>}
                                            >
                                                <MenuItem value={''}>
                                                    <em>Sélectionnez la rubrique</em>
                                                </MenuItem>
                                                {rubrics.items!.map(rubric => (
                                                    <MenuItem
                                                        key={rubric.id}
                                                        value={String(rubric.id)}
                                                    >
                                                        {rubric.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {hasError('rubric_id') &&
                                        <FormHelperText error={true}>
                                            {getError('rubric_id')}
                                        </FormHelperText>}
                                </FormControl>
                                <StyledIconButton
                                    onClick={() => refetchRubrics()}
                                >
                                    <Refresh/>
                                </StyledIconButton>
                            </Box>
                        )}
                        <Box sx={{
                            my: 2,
                            maxWidth: 500,
                            width: '100%',
                            display: 'flex',
                            flexFlow: 'column',
                            gap: '1rem'
                        }}
                        >
                            <TextField
                                error={hasError('variants')}
                                type="text"
                                variant="outlined"
                                id="variants"
                                label="Définissez les différentes variantes de la question"
                                multiline={true}
                                rows={10}
                                maxRows={100}
                                required
                                fullWidth
                                helperText={getError('variants')}
                                {...register('variants')}
                            />
                        </Box>
                    </TabForm>
                </TabPanel>

                {question && (
                    <TabPanel
                        value="2">
                        <ResponsesPage/>
                    </TabPanel>
                )}
            </TabContext>
        </Box>
    );
}

export default QuestionForm;
