import {Box} from "@mui/material";
import AgentRatingStatsPage from "../../stats/pages/agent-rating-stats-page";
import {StatCard} from "../../../lib/ui/src/components/StatCard";
import {formatNumber, frenchCurrencyFormat} from "../../../utils";
import {
    AccountBalanceWalletOutlined,
    AdminPanelSettingsOutlined,
    ArchiveOutlined,
    CampaignOutlined,
    DoneAllOutlined,
    GppGoodOutlined,
    GroupOutlined,
    LayersOutlined,
    MapsHomeWorkOutlined,
    PendingActionsOutlined,
    PersonPinCircleOutlined,
    PhoneAndroidOutlined,
    QuestionAnswerOutlined,
    QueueOutlined,
    ReplyOutlined,
    SmsOutlined
} from "@mui/icons-material";
import MessageIcon from "@mui/icons-material/Message";
import {useGetNumericStatsQuery} from "../../stats/services/statsApi";
import LinearLoader from "../../../components/Loader/LinearLoader";
import {CaseTrackingStats} from "./CaseTrackingStats";
import {AgentConversationsStats} from "../../stats/components/AgentConversationsStats";
import React from "react";

export const AdminDashboard = () => {

    const {data, isLoading} = useGetNumericStatsQuery(undefined)


    if (isLoading) {
        return <LinearLoader/>
    }

    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        // large screens, 1 columns of 300px and 1fr
                        lg: 'repeat(1, 280px) 1fr',
                        // medium screens, 1 columns of 300px and 1fr
                        md: 'repeat(1, 200px) 1fr',
                        // small screens, 1 columns of 300px and 1fr
                        gap: '2rem',
                    }
                }}
            >
                {data && (
                    <>
                        <Box
                        >
                            <AgentRatingStatsPage
                                data={data.item!.top_rated_agents}
                            />
                        </Box>
                        <Box
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 3,
                            }}
                        >
                            <AgentConversationsStats/>
                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: {
                                    // large screens
                                    lg: 'repeat(4, 1fr)',
                                    // medium screens
                                    md: 'repeat(3, 1fr)',
                                    // small screens
                                    xs: 'repeat(2, 1fr)',
                                },
                                gap: '1rem',
                                mb: '20px',
                                alignItems: 'flex-start',
                                alignContent: 'flex-start',
                            }}>
                                <>
                                    <StatCard
                                        label={'Crédit disponible'}
                                        value={`${frenchCurrencyFormat(data.item!.sms_credit.solde)}  ${data.item!.sms_credit.currency}`}
                                        icon={<AccountBalanceWalletOutlined/>}
                                    />
                                    <StatCard
                                        label={'SMS disponibles'}
                                        value={frenchCurrencyFormat(data.item!.sms_credit.solde / 15)}
                                        icon={<SmsOutlined/>}
                                        subLabel={`${frenchCurrencyFormat(data.item!.sms_credit.solde)}  ${data.item!.sms_credit.currency}`}
                                    />
                                    <StatCard
                                        label={'Utilisateurs'}
                                        value={formatNumber(data.item!.users)}
                                        icon={<GroupOutlined/>}
                                    />
                                    <StatCard
                                        label={'CB Distants'}
                                        value={formatNumber(data.item!.remote_cbs)}
                                        icon={<PersonPinCircleOutlined/>}
                                    />
                                    <StatCard
                                        label={'Agents'}
                                        value={formatNumber(data.item!.agents)}
                                        icon={<GppGoodOutlined/>}
                                    />
                                    <StatCard
                                        label={'CB ou Admin'}
                                        value={formatNumber(data.item!.admins)}
                                        icon={<AdminPanelSettingsOutlined/>}
                                    />
                                    <StatCard
                                        label={'Clients Chat Bot'}
                                        value={formatNumber(data.item!.clients)}
                                        icon={<PhoneAndroidOutlined/>}
                                    />
                                    <CaseTrackingStats cases={data.item!.cases}/>
                                    <StatCard
                                        label={'Total des brigades'}
                                        value={formatNumber(data.item!.brigades)}
                                        icon={<MapsHomeWorkOutlined/>}
                                    />
                                    <StatCard
                                        label={'Requêtes en attentes'}
                                        value={formatNumber(data.item!.pending_conversations)}
                                        icon={<PendingActionsOutlined/>}
                                    />
                                    <StatCard
                                        label={'Requêtes traitées'}
                                        value={formatNumber(data.item!.approved_conversations)}
                                        icon={<DoneAllOutlined/>}
                                    />
                                    <StatCard
                                        label={'Total requêtes'}
                                        value={formatNumber(data.item!.conversations_requests)}
                                        icon={<QueueOutlined/>}
                                    />
                                    <StatCard
                                        label={'Rubriques'}
                                        value={formatNumber(data.item!.rubrics)}
                                        icon={<LayersOutlined/>}
                                    />
                                    <StatCard
                                        label={'Questions'}
                                        value={formatNumber(data.item!.questions)}
                                        icon={<QuestionAnswerOutlined/>}
                                    />
                                    <StatCard
                                        label={'Réponses'}
                                        value={formatNumber(data.item!.responses)}
                                        icon={<ReplyOutlined/>}
                                    />
                                    <StatCard
                                        label={'Nouvelles'}
                                        value={formatNumber(data.item!.notifications)}
                                        icon={<CampaignOutlined/>}
                                    />
                                    <StatCard
                                        label={'Messages'}
                                        value={formatNumber(data.item!.messages)}
                                        icon={<MessageIcon/>}
                                    />
                                    <StatCard
                                        label={'Archives'}
                                        value={formatNumber(data.item!.archived_conversations)}
                                        icon={<ArchiveOutlined/>}
                                    />
                                </>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </>
    )
}
