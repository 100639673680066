import {Box, MenuList, Popover, Typography} from "@mui/material";
import {IAccountMenuProps} from "./props";


const AccountMenu = ({children, anchorEl, open, onClose, fullname, title}: IAccountMenuProps) => {
    return (
        <Popover
            open={open}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            anchorEl={anchorEl}
            PaperProps={{
                sx: {
                    width: '300px',
                    mt: 1.6,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    right: 1.6,
                    left: 'auto!important'
                }
            }}
        >
            <Box
                sx={{
                    py: 1.5,
                    px: 2
                }}
            >
                <Typography variant="overline">
                    {title}
                </Typography>
                <Typography
                    component={'div'}
                    color="text.secondary"
                    variant="body2"
                >
                    {fullname}
                </Typography>
            </Box>
            <MenuList
                disablePadding
                sx={{
                    '& > *': {
                        '&:first-of-type': {
                            borderTopColor: 'divider',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1px'
                        },
                        padding: '12px 16px'
                    }
                }}
            >
                {children}
            </MenuList>
        </Popover>
    )
}

export default AccountMenu
