import {AccountTreeOutlined, LiveHelpOutlined} from "@mui/icons-material";
import {lazy} from "react";
import {IRouteType} from "../../../types";
import {IaRoutesType} from "./types";


const IntentsPage = lazy(() => import("../pages/intents-page"));
const UnknownQuestionsPage = lazy(() => import("../pages/unknown-questions-page"));

export const iaRoutes: IaRoutesType = {
    intents: {
        path: '/intents',
        component: <IntentsPage/>,
        title: 'Flow',
        icon: <AccountTreeOutlined/>,
        permission: 'ROLE_AGENT',
    },
    unknownQuestions: {
        path: '/unknown-questions',
        component: <UnknownQuestionsPage/>,
        title: 'Questions inconnues',
        icon: <LiveHelpOutlined/>,
        permission: 'ROLE_AGENT',
    }
}


export const iaSidebarRoutes: Omit<IRouteType, 'component'>[] = Object.entries(iaRoutes)
    .map(([_, {component, ...rest}]) => (
        {
            ...rest,
        }
    ))
