import {createApi} from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from "../../../redux/rtk/axiosBaseQuery";
import {
    DeleteItemArgs,
    GetItemQueryArgs,
    GetItemsQueryArgs,
    IJsonResourceResponse,
    PostItemArgs,
    PutItemArgs
} from "../../share/types";
import {IUser} from "../entities";


export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: axiosBaseQuery({baseUrl: '/api/v1'}),
    tagTypes: ['Users', 'Agents', 'Cbs'],
    endpoints: (builder) => (
        {
            getUsers: builder.query<IJsonResourceResponse<IUser, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/users?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Users' as const,
                                    id,
                                }
                            )),
                            {type: 'Users', id: 'LIST'},
                        ]
                        : [{type: 'Users', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IUser, 'multiple'>) => results,
            }),
            getAgents: builder.query<IJsonResourceResponse<IUser, 'multiple'>, GetItemsQueryArgs & {
                filter?: string
            }>({
                query: ({page, limit, searchTerm, filter}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    const filters = filter ? `&${filter}` : '';

                    return {
                        url: `/users/agents?${pageQuery}${limitQuery}${search}${filters}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Users' as const,
                                    id,
                                }
                            )),
                            {type: 'Users', id: 'LIST'},
                        ]
                        : [{type: 'Users', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IUser, 'multiple'>) => results,
            }),
            getRemoteCbs: builder.query<IJsonResourceResponse<IUser, 'multiple'>, GetItemsQueryArgs>({
                query: ({page, limit, searchTerm}) => {
                    const search = searchTerm ? `&q=${searchTerm}` : '';
                    const pageQuery = `&page=${page || 1}`;
                    const limitQuery = `&limit=${limit || 25}`;
                    return {
                        url: `/users/cbs?${pageQuery}${limitQuery}${search}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result) =>
                    result
                        ? [
                            ...result.items!.map(({id}) => (
                                {
                                    type: 'Cbs' as const,
                                    id,
                                }
                            )),
                            {type: 'Cbs', id: 'LIST'},
                        ]
                        : [{type: 'Cbs', id: 'LIST'}],
                transformResponse: (results: IJsonResourceResponse<IUser, 'multiple'>) => results,
            }),
            getUser: builder.query<IUser, GetItemQueryArgs>({
                query: (id) => {
                    return {
                        url: `/users/${id}`,
                        method: 'GET',
                        credentials: 'include',
                    };
                },
                providesTags: (result, error, {id}) => [{type: 'Users', id}],
                transformResponse: (result: IJsonResourceResponse<IUser, 'single'>) => result.item!,
            }),
            createUser: builder.mutation<IUser, PostItemArgs<IUser>>({
                query: ({data}) => {
                    return {
                        url: '/users',
                        method: 'POST',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Users', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IUser, 'single'>) => result.item!,
            }),
            updateUser: builder.mutation<IUser, PutItemArgs<IUser>>({
                query: ({data, id}) => {
                    return {
                        url: `/users/${id}`,
                        method: 'PUT',
                        credentials: 'include',
                        data,
                    };
                },
                invalidatesTags: [{type: 'Users', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IUser, 'single'>) => result.item!,
            }),
            toggleUserStatus: builder.mutation<IUser, Omit<PutItemArgs<IUser>, 'data'>>({
                query: ({id}) => {
                    return {
                        url: `/users/${id}/toggle`,
                        method: 'PATCH',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Users', id: 'LIST'}],
                transformResponse: (result: IJsonResourceResponse<IUser, 'single'>) => result.item!,
            }),
            deleteUser: builder.mutation<IUser, DeleteItemArgs>({
                query: ({id}) => {
                    return {
                        url: `/users/${id}`,
                        method: 'DELETE',
                        credentials: 'include',
                    };
                },
                invalidatesTags: [{type: 'Users', id: 'LIST'}],
            }),
        }
    ),
});

export const {
    useGetUsersQuery,
    useGetAgentsQuery,
    useGetRemoteCbsQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useToggleUserStatusMutation,
    useDeleteUserMutation,
} = userApi;
