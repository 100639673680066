import styled from "@emotion/styled";
import {Box, Button, ListItem, Tooltip} from "@mui/material";
import React from "react";
import {NavLink} from "react-router-dom";

import {ISidebarItemProps} from "./props";


export const SidebarItem = ({navLinkClass, path, icon, title, ...props}: ISidebarItemProps) => {
    return (
        <ListItem
            disableGutters
            sx={{
                display: 'flex',
                mb: 1,
                mt: 1,
                py: 0,
                px: 2
            }}
            {...props}
        >
            <Tooltip title={title || ''} placement="right">
                <StyledNavLink
                    to={path}>
                    <Button
                        startIcon={icon}
                        disableRipple
                        sx={{
                            borderRadius: 1,
                            color: 'neutral.900',
                            fontWeight: 'medium',
                            justifyContent: 'flex-start',
                            px: 3,
                            textAlign: 'left',
                            textTransform: 'none',
                            width: '100%',
                            '& .MuiButton-startIcon': {
                                color: 'neutral.900',
                                '& svg': {
                                    fontSize: '1.5rem',
                                }
                            },
                            '&:hover': {
                                backgroundColor: '#023766!important',
                                color: 'neutral.100',

                                '& .MuiButton-startIcon': {
                                    color: 'neutral.100',
                                },
                            }
                        }}
                    >
                        <Box sx={{
                            flexGrow: 1,
                            color: 'text.0',
                            fontSize: '1.2rem',
                        }}>
                            {title}
                        </Box>
                    </Button>
                </StyledNavLink>
            </Tooltip>
        </ListItem>
    )
}

const StyledNavLink = styled(NavLink)(({theme}) => ({
    maxWidth: '100%',
    width: '100%',
    display: 'inline-block',
    margin: 0,
    '&\.active button': {
        backgroundColor: '#023766',
        fontWeight: 'fontWeightBold',
        '& *': {
            color: '#fff!important'
        },
        '& .MuiButton-startIcon': {
            color: '#fff!important'
        },
    }
}))
export default SidebarItem;
