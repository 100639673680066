import React from 'react';
import {Dayjs} from "dayjs";
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {BrowserDatePicker} from '../BrowserDatePicker';

const periodOptions = [
    {label: "07 derniers jours", value: '1_week'},
    {label: "30 derniers jours", value: '30_days'},
    {label: "Il y'a une année", value: '1_year'},
    {label: "Semaine en cours", value: 'current_week'},
    {label: "Mois en cours", value: 'current_month'},
    {label: "Année en cours", value: 'current_year'},
    {label: 'Choisir un plage', value: 'custom'},
];

interface IDateFilterProps {
    startDate: Date;
    endDate: Date;
    period: string;
    handlePeriodChange: (event: SelectChangeEvent<string>) => void;
    setStartDate: (date: Date) => void;
    setEndDate: (date: Date) => void;
}

export const DateFilter = ({
                               startDate,
                               endDate,
                               period,
                               handlePeriodChange,
                               setStartDate,
                               setEndDate
                           }: IDateFilterProps) => {

    return (
        <>
            <FormControl variant="outlined">
                <InputLabel id="period-select-label">
                    Sélectionner une période
                </InputLabel>
                <Select
                    value={period}
                    onChange={handlePeriodChange}
                    label={"Sélectionner une période"}
                    labelId="period-select-label"
                    variant={"outlined"}
                >
                    {periodOptions.map((option, index) => (
                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {period === 'custom' && (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'center',
                    }}
                >
                    <BrowserDatePicker
                        sx={{
                            maxWidth: 150,
                        }}
                        currentDate={startDate}
                        minDate={null}
                        maxDate={new Date()}
                        label={"Date de début"}
                        onSelectedDateChange={(date: Dayjs) => setStartDate(date.toDate())}
                    />
                    <BrowserDatePicker
                        sx={{
                            maxWidth: 150,
                        }}
                        currentDate={endDate}
                        minDate={startDate}
                        maxDate={new Date()}
                        label={"Date de fin"}
                        onSelectedDateChange={(date: Dayjs) => setEndDate(date.toDate())}
                    />
                </Box>
            )}
        </>
    );
};
